<template>
  <div class="corporate-image-section">
    <div v-if="edit">
      <div style="" class="upload-image-wrapper">
        <button class="add-files-button" text @click="startUploadImage()">
          <img
            style="padding-right: 8px; width 16px; height: 16px;"
            src="../../assets/images/image.svg"
            alt=""
          />
          <p
            class="content-small"
            style="
              font-weight: 400;
              min-width: 64px !important;
              justify-content: center;
            "
          >
            {{ $t("UploadImage") }}
          </p>
        </button>
        <input
          style="display: none"
          type="file"
          id="corp-image-uploader"
          @change="(event) => uploadImage(event)"
        />
        <v-row v-if="image" class="file-row">
          <span style="display: flex; gap: 8px">
            <a :href="image.value" target="_blank" class="file-name">
              {{ image.name }}
            </a>
            <img
              src="../../assets/images/action-delete.svg"
              alt=""
              style="cursor: pointer"
              @click="deleteImage()"
            />
          </span>
        </v-row>
      </div>

      <div class="bottom-con" v-show="edit">
        <button class="transparent-button" @click="cancelPress">
          {{ $t("Cancel") }}
        </button>
        <button
          @click="savePress"
          class="transparent-button"
          style="background: black !important; color: white"
        >
          <img
            src="../../assets/images/checkmarkCircled.svg"
            class="img16"
            alt=""
          />
          <span>
            {{ $t("Save") }}
          </span>
        </button>
      </div>
    </div>
    <div v-else>
      <div class="corporate-image-wrapper">
        <img :key="image.value" v-if="image" :src="image.value" alt="" />
        <div class="corporate-image-placeholder" v-else></div>
        <p class="content-normal">
          {{ $t("Image's") + " " + $t("place") + ": " + $t("3rd") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Compress from "compress.js";
//import adminApi from "@/http/admin";

export default {
  name: "CorporateImageSection",
  components: {},
  props: ["edit", "company"],
  data() {
    return {
      image: null,
      saving: false,
    };
  },
  created() {
    if (this.company.listings.corporateImage) {
      this.image = this.company.listings.corporateImage;
    }
  },
  methods: {
    startUploadImage() {
      const fileContentLoaded = setInterval(() => {
        if (document.getElementById("corp-image-uploader")) {
          document.getElementById("corp-image-uploader").click();
          clearInterval(fileContentLoaded);
        }
      }, 100);
    },
    async uploadImage(event) {
      let corpImage = event.target.files[0];
      let files = [];
      files.push(...event.target.files);
      const compress = new Compress();
      compress
        .compress(files, {
          size: 1, // the max size in MB, defaults to 2MB
          quality: 0.65, // the quality of the image, max is 1,
          maxWidth: 1920, // the max width of the output image, defaults to 1920px
          maxHeight: 1920, // the max height of the output image, defaults to 1920px
          resize: true, // defaults to true, set false if you do not want to resize the image width and height
          rotate: false, // See the rotation section below
        })
        .then(async (data) => {
          let imageValue = null;
          data.forEach((compressedImg) => {
            imageValue = compressedImg.prefix + compressedImg.data;
          });
          //let imageBase64 = await this.convertBase64(corpImage);

          this.image = {
            name: corpImage.name,
            value: imageValue,
          };
        })
        .catch(() => {
          this.toastError(this.$t("ErrorUpdating"));
          return;
        });
    },

    cancelPress() {
      this.$emit("closeEdit");
    },
    async savePress() {
      try {
        if (this.saving) return;
        this.saving = true;
        await axios.post(`/api/company/upload-corporate-image`, {
          corporateImage: this.image,
        });
        this.$emit("closeEdit");
        this.toastSuccess(this.$t("CorporateImageSaved"));
        this.saving = false;
      } catch (e) {
        this.toastError(this.$t("ErrorUpdating"));
      }
    },
    deleteImage() {
      this.image = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-con {
  margin-top: 24px;
  display: flex;
  justify-content: end;
  gap: 8px;
}

.corporate-image-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 32px;
  img {
    width: 200px;
    max-width: 200px;
    height: auto;
  }
}

.corporate-image-placeholder {
  width: 200px;
  height: 108px;
  background: #f4f5f7;
}

.upload-image-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.add-files-button {
  color: #000000;
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  line-height: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: 1px dashed #000000;
  width: 112px;
  height: 32px;
}
.file-name {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
</style>
