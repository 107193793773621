<template>
  <div class="overlay" id="overlay-property">
    <div id="panel-wrapper-property" class="modal-wrapper">
      <div
        class="content-overlay"
        style="height: 100%; display: flex; flex-direction: column"
        id="content-wrapper"
      >
        <div class="sticky-header" style="z-index: 126">
          <v-row class="top-row" style="height: 24px; padding-left: 32px">
            <h4 style="height: 24px">
              {{ $t("ChangeContactInformation") }}
            </h4>
          </v-row>
        </div>
        <div style="padding: 24px 32px; height: 100%; overflow-y: scroll">
          <v-row style="padding-bottom: 12px">
            <h5>{{ $t("Company") }}</h5>
          </v-row>
          <v-row style="gap: 8px">
            <BaseInput
              :placeholder="$t('CompanyName')"
              v-model="form.companyName"
            />
            <BaseInput
              :placeholder="$t('FranchiseName')"
              v-model="form.franchiseName"
            />
            <BaseInput
              :placeholder="$t('RegCode')"
              v-model="form.regCode"
            />
            <BaseInput
              :placeholder="$t('VATNumber')"
              v-model="form.vatNumber"
            />
          </v-row>

          <v-row style="margin-top: 32px">
            <h5>{{ $t("Address") }}</h5>
          </v-row>

          <v-row style="gap: 8px; margin-top: 24px">
            <BaseInput
              :placeholder="$t('Street')"
              v-model="form.street"
            />
           <BaseInput
              :placeholder="$t('ApartmentNumber')"
              v-model="form.apartmentNumber"
            />
            <v-row style="display: flex; flex-wrap: nowrap; gap: 12px">
             <BaseInput
              :placeholder="$t('City')"
              v-model="form.city"
            />
             <BaseInput
              :placeholder="$t('ZipCode')"
              v-model="form.zipCode"
            />
            </v-row>
             <BaseInput
              :placeholder="$t('Country')"
              v-model="form.country"
            />
          </v-row>

          <v-row style="margin-top: 32px">
            <h5>{{ $t("Contact") }}</h5>
          </v-row>

          <v-row style="gap: 8px; margin-top: 24px">
            <BaseInput
              :placeholder="$t('WebsiteUrl')"
              v-model="form.website"
            />
             <BaseInput
              :placeholder="$t('Email')"
              v-model="form.email"
            />
             <BaseInput
              :placeholder="$t('PhoneNumber')"
              v-model="form.phoneNumber"
            />
          </v-row>

          <v-row style="margin-top: 32px">
            <h5>{{ $t("BankAccount") }}</h5>
          </v-row>

          <v-row style="gap: 8px; margin-top: 24px">
            <BaseInput
              :id="'property-owner-business-field'"
              :placeholder="$t('BankName')"
              v-model="form.bankName"
            />
             <BaseInput
              :id="'property-owner-business-field'"
              :placeholder="$t('BICSWIFT')"
              v-model="form.swift"
            />
             <BaseInput
              :id="'property-owner-business-field'"
              :placeholder="$t('BankAccountNumber')"
              v-model="form.accountNumber"
            />
          
          </v-row>
        </div>
        <div class="modal-footer">
          <div class="buttons-row" style="padding-left: 24px">
            <button @click="closeModal()" class="cancel">
              {{ $t("Cancel") }}
            </button>
            <button  class="save-button" @click="updateContactInformation()">
              <img
                style="padding-right: 8px"
                src="../../assets/images/Submit.svg"
                alt=""
              />
              {{ $t("Save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
import BaseInput from "@/components/common/BaseInput";

export default {
  components: {
    BaseInput,
  },
  name: "SidepanelAdd",
  props: ["groupId", "groupData"],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      form: {
        companyName: "",
        franchiseName: null,
        regCode: null,
        vatNumber: null,
        street: null,
        apartmentNumber: null,
        city: null,
        zipCode: null,
        country: null,
        website: null,
        email: null,
        phoneNumber: null,
        bankName: null,
        swift: null,
        accountNumber: null,
      },
    };
  },
  created() {
    this.mapToFormData();
  },
  mounted() {
    this.hideOverlay()
  },
  watch: {},
  beforeDestroy() {
    if (!this.fromAnotherPanel) {
      document.documentElement.style.overflow = "visible";
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["changeSidepanelStateProperties"]),
    closeModal() {
      this.$emit("closed");
    },
    async updateContactInformation() {
      this.$emit("approved", {groupId: this.groupId, form: this.form, groupData: this.groupData})
    },
    mapToFormData() {
      if (this.groupData.contactInformation) {
        this.form = this.groupData.contactInformation;
      }
    },
    hideOverlay() {
      document.documentElement.style.overflow = "hidden";

      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };
    },
  
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#panel-wrapper-property {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

#wrapped-header-property {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}

.deal-type-lable {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.v-text-field--outlined .v-label {
  top: 20px;
}

::v-deep .delete {
  padding: 0 !important;
}

::v-deep .plus {
  display: none;
}

.calender-label {
  margin-top: -5px;
  left: auto;
  right: auto;
  position: absolute;
  font-size: 12px;
  font-family: Inter;
  background-color: white;
  margin-left: 12px;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  border-bottom: 1px solid #e6e8ec;
  background: white;
  height: 97px;
  width: 560px;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.top-row {
  width: 100%;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  z-index: 9;
}
.flex-column-gen {
  margin-top: 17px !important;
}

::v-deep .mainMessage {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  //line-height: 20px;
  text-align: center;
  color: #000000;
}

::v-deep .beforeUpload .icon {
  height: 20px;
}

.image-uploader {
  //min-width: 896px;
  min-height: 96px;
  border: 1px dashed #c3c4c6;
  box-sizing: border-box;
  border-radius: 8px;
}

.project-type-selected-con-Sale {
  background: $lightgreen !important;
  color: $green !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-LeaseOut {
  background: $lightpink !important;
  color: $magenta !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Purchase {
  background: $lightblue !important;
  color: $blue !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-AquireLease {
  background: $beige !important;
  color: $orange !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Referral {
  background: $lightbrown !important;
  color: $brown !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-con {
  font-style: normal;
  font-weight: normal;
  border: 1px solid $grey500;
  box-sizing: border-box;
  border-radius: 8px;

  background: #fff;
  color: #000;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
}
.modal-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  padding-left: 0px;
  z-index: 7;
  width: 560px;
  border: 1px solid #e6e8ec;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.modal-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.modal-content {
  font-size: 14px;
  padding: 110px 32px 140px 32px;
}

.modal-footer {
  justify-content: flex-end;
  z-index: 126;
  border-top: 1px solid #e6e8ec;
  background: white;
  width: 560px;
}

.faded-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $grey950;
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 50px 64px;
  height: 112px;
  font-family: Inter !important;
  color: #000000;
  width: 560px;
  background-color: white;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

::v-deep .theme--light.v-select .v-select__selections {
  color: #000000;
  line-height: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 16px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 18px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 18px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 49.2%;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  height: 56px;
  padding: 0px !important;

  &:focus {
    outline: none !important;
  }
}

.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  text-transform: none;
  width: 103px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.cancel-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400 !important;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.textarea {
  width: 100%;
  height: 96px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
}

.delete-button {
  margin-right: 168px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}

.input-edit {
  margin-top: 12px;
}

::v-deep .v-messages {
  display: none;
}

::v-deep .radio-group-label .v-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
</style>
