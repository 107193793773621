<template>
  <div class="wrapper">
    <div v-if="!loaded">
      <div
          style="margin-top: 24px; position: absolute; top: 30%; left: 50%"
          class="loader"
      ></div>
    </div>

    <div v-if="loaded" id="settings-content">
      <BaseDetailviewContainer :width="680" :padding="24">
        <BaseAccordion
            @buttonPressed="editVisibility"
            :title="$t('UserRightsManagement')"
            :button-name="$t('Edit')"
            :padding-top="'0px'"
            :button-opens-accordion="true"
            :button-icon="'edit_16_16.svg'"
            :identifier="'userRights'"
            :show-bot-divider="true"
            :parent-name="'settings'"
            :toggle-button="true"
            @toggleAction="toggleUserVisibility"
            :toggle-text="$t('UserCanLimitVisibility')"
            :toggle-status="visibilityLimitable"
        >
          <!--    <div class="header-row">
            <div class="header-toggle-wrapper space-between">
              <h5>{{ $t("UserRightsManagement") }}</h5>
              <EditButton
                v-if="!visibilityEdit && user.isAdmin"
                @clicked="editVisibility"
              />
            </div>
          </div> -->
          <!--   <div class="setting-row">
              <v-checkbox
                hide-details
                v-model="visibilityLimitable"
                style="padding: 0"
                :disabled="!visibilityEdit || !user.isAdmin"
              >
                <template v-slot:label>
                  <p
                    class="subheading"
                    style="
                      color: black !important;
                      flex: 8;
                      letter-spacing: normal;
                    "
                  >
                    {{ $t("UserCanLimitVisibility") }}
                  </p>
                </template>
              </v-checkbox>
            </div> -->
          <div
              class="setting-row"
              style="max-width: 200px"
              :key="updateVisibilityValue"
          >
            <!--   <p class="subheading" style="color: black !important; flex: 8">
                {{ $t("DefaultVisibilityValue") }}
              </p> -->
            <BaseDropdown
                :id="'sidepanel-contact-ownership'"
                :items="visibilityValues"
                v-model="visibilityValue"
                style="flex: 4"
                :dropdown-allow-null="false"
                :placeholder="$t('DefaultVisibilityValue')"
                :show-dropdown-arrow="true"
                :disabled="!visibilityEdit"
            />
          </div>

          <BaseToggle
              style="margin-left: 8px; margin-top: 16px"
              :value="user.companyPolicies.filters.projects.showAll"
              @change="(val) => changePolicyFilter('projects', val)"
              :label="$t('ShowAllProjectsToAllUsers')"
          />

          <BaseToggle
              style="margin-left: 8px; margin-top: 16px"
              :value="user.companyPolicies.filters.transactions.showAll"
              @change="(val) => changePolicyFilter('transactions', val)"
              :label="$t('ShowAllTransactionsToAllUsers')"
          />

          <div class="submit-cancel-wrapper">
            <CancelButton
                v-if="visibilityEdit"
                class="top-24"
                @clicked="editVisibility"
            />
            <SubmitButton
                v-if="visibilityEdit"
                class="top-24"
                @clicked="saveVisibility"
            />
          </div>
        </BaseAccordion>
        <BaseAccordion
            :title="$t('ListingsPublishing')"
            :separator-line="true"
            :identifier="'listingChannels'"
            :show-bot-divider="true"
            :parent-name="'settings'"
        >
          <div class="setting-section">
            <div v-if="company.access.features?.modules?.listings?.features?.aml?.enabled && amlDropdownLoaded">
              <div class="content-bold">{{$t('AMLSettings')}}</div>
              <div style="width: 200px; padding: 12px 0;">
                <DropdownMulti
                    :items="amlFeatureDropdownItems"
                    @input="amlValueChanged"
                    :selected-items="selectedAmlDropdownItems"
                    v-model="selectedAmlDropdownItems"
                    :translate="true"
                    :display-key="'name'"
                    :value-key="'value'"
                    :width="'200px'"
                    :default-value="$t('None')"
                />
              </div>
            </div>
            <!--
            <div>
              <div>feeds</div>
              <div v-for="marketplace in company.marketplaces" :key="marketplace.name">
                <p>{{marketplace.name}}</p>
                <template v-if="marketplace.feeds && marketplace.feeds.length > 0">
                  <div v-for="feed in marketplace.feeds" :key="feed.id">
                    feed output url + name
                  </div>
                </template>
                <div v-else>
                  // feed output url
                </div>
              </div>
            </div>
            -->
            <!--   <div class="header-row">
            <div class="header-toggle-wrapper">
              <h5>{{ $t("ListingChannels") }}</h5>
            </div>
          </div> -->
            <div class="xml-data" style="margin-top: 0 !important">
              <div class="content-bold" style="padding: 12px 0;">{{$t('Outputs')}}</div>
              <p class="content-small content-semibold">
                {{ user.isItaly ? "Immobiliare" : "KV24" }}
              </p>
              <a
                  :href="`https://app.runproperty.com/xmls/kv24/${user.companyId}.xml`"
                  class="link"
              >
                {{
                  `https://app.runproperty.com/xmls/kv24/${user.companyId}.xml`
                }}
              </a>
            </div>
            <div class="xml-data">
              <p class="content-small content-semibold">
                {{ user.isItaly ? "Idealista" : "CITY24" }}
              </p>
              <a
                  v-if="user.companyId === '5p1EOo4yqjeiYCN3R16w'"
                  :href="`https://app.runproperty.com/xmls/city24/0XMZ7rJji5wTQuKp_mw7.xml`"
                  class="link"
              >
                {{
                  `https://app.runproperty.com/xmls/city24/0XMZ7rJji5wTQuKp_mw7.xml`
                }} </a
              ><a
                v-else
                :href="`https://app.runproperty.com/xmls/city24/${user.companyId}.xml`"
                class="link"
            >
              {{
                `https://app.runproperty.com/xmls/city24/${user.companyId}.xml`
              }}
            </a>
            </div>
            <div class="xml-data">
              <p class="content-small content-semibold">
                {{ user.isItaly ? "Casa" : "KV.ee" }}
              </p>
              <a
                  :href="`https://app.runproperty.com/xmls/kv/${user.companyId}`"
                  class="link"
              >
                {{ `https://app.runproperty.com/xmls/kv/${user.companyId}` }}
              </a>
            </div>
            <div
                class="xml-data"
                style="margin-top: 12px"
                v-if="company.outputLinks && company.outputLinks.www"
            >
              <p class="content-small content-semibold">WWW</p>
              <a
                  :href="getCompanyWwwLink()"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="link"
              >
                {{ getCompanyWwwLink() }}
              </a>
            </div>
            <div class="xml-data" v-if="showOkidoki()">
              <p class="content-small content-semibold">
                Okidoki
              </p>
              <a
                  :href="`https://app.runproperty.com/xmls/okidoki/${user.companyId}.xml`"
                  class="link"
              >
                {{ `https://app.runproperty.com/xmls/okidoki/${user.companyId}.xml` }}
              </a>
            </div>
            <div class="xml-data" v-if="showSS()">
              <p class="content-small content-semibold">
                SS.com
              </p>
              <a
                  :href="`https://app.runproperty.com/xmls/ss/${user.companyId}.xml`"
                  class="link"
              >
                {{ `https://app.runproperty.com/xmls/ss/${user.companyId}.xml` }}
              </a>
            </div>
            <div class="xml-data" v-if="showChristies()">
              <p class="content-small content-semibold">
                Christies
              </p>
              <a
                  :href="`https://app.runproperty.com/xmls/christies/${user.companyId}.xml`"
                  class="link"
              >
                {{ `https://app.runproperty.com/xmls/christies/${user.companyId}.xml` }}
              </a>
            </div>
          </div>
        </BaseAccordion>
        <BaseAccordion
            @buttonPressed="editActiveDays"
            :title="$t('ListingsDaysActive')"
            :button-name="$t('Edit')"
            :show-bot-divider="true"
            :button-opens-accordion="true"
            :button-icon="'edit_16_16.svg'"
            :identifier="'listingsDaysActive'"
            :parent-name="'settings'"
            :toggle-button="true"
            @toggleAction="toggleActiveDaysStatus"
            :toggle-text="activeDaysStatusText"
            :toggle-status="activeDaysEnabled"
        >
          <div class="setting-section">
            <p
                v-if="!activeDaysEdit"
                class="content"
                style="color: black !important"
            >
              {{ activeDays }}
            </p>
            <BaseInput
                style="width: 88px"
                :placeholder="$t('Days')"
                v-if="activeDaysEdit"
                v-model="activeDays"
                type="number"
            />

            <div class="submit-cancel-wrapper">
              <CancelButton
                  v-if="activeDaysEdit"
                  class="top-24"
                  @clicked="editActiveDays"
              />
              <SubmitButton
                  v-if="activeDaysEdit"
                  class="top-24"
                  @clicked="saveActiveDays"
              />
            </div>
          </div>
        </BaseAccordion>
        <BaseAccordion
            @buttonPressed="editListingsCorporateImage"
            :title="$t('ListingsCorporateImage')"
            :button-name="$t('Edit')"
            :show-bot-divider="true"
            :button-opens-accordion="true"
            :button-icon="'edit_16_16.svg'"
            :identifier="'ListingsCorporateImage'"
            :parent-name="'settings'"
            :toggle-button="true"
            @toggleAction="toggleListingCorporateImageStatus"
            :toggle-text="
            listingCorporateImageStatus ? $t('Active') : $t('Inactive')
          "
            :toggle-status="listingCorporateImageStatus"
        >
          <div class="setting-section">
            <CorporateImageSection
                :edit="listingsCorporateImageEdit"
                :company="company"
                @closeEdit="listingsCorporateImageEdit = false"
                @updateCompanyData="getCompanyInfo"
            />
          </div>
        </BaseAccordion>
        <BaseAccordion
            @buttonPressed="openAddSharedResource++"
            :button-name="$t('Add')"
            :title="$t('SharedResources')"
            :identifier="'SharedResources'"
            :show-bot-divider="true"
            :button-opens-accordion="true"
            :button-icon="'plus.svg'"
            :parent-name="'settings'"
        >
          <div class="setting-section">
            <div>
              <SharedResources :add-resource="openAddSharedResource"/>
            </div>
          </div>
        </BaseAccordion>

        <!-- <BaseAccordion
            :title="$t('Watermark')"
            :show-bot-divider="true"
            :button-opens-accordion="true"
            :identifier="'watermark'"
            :parent-name="'settings'"
        >
          <div class="setting-section">
            <WatermarkEditing
                :showLogoPositionModal="showLogoPositionModal"
                @open-modal="openWaterMarkModal"
                @close-modal="showLogoPositionModal = false"
                :company="company"
            >
            </WatermarkEditing>
          </div>
        </BaseAccordion> -->

        <BaseAccordion
            @buttonPressed="editFooter"
            :button-opens-accordion="true"
            :title="$t('FooterText')"
            :button-name="$t('Edit')"
            :identifier="'footerText'"
            :button-icon="'edit_16_16.svg'"
            :parent-name="'settings'"
            :toggle-button="true"
            :show-bot-divider="true"
            @toggleAction="toggleFooterStatus"
            :toggle-text="footerStatusText"
            :toggle-status="footerEnabled"
        >
          <div class="setting-section">
            <!--   <div class="header-row">
          <div class="header-toggle-wrapper">
            <h5>{{ $t("FooterText") }}</h5>
            <div class="toggle-wrapper">
              <img
                @click="toggleFooterStatus"
                style="cursor: pointer"
                :src="
                  footerEnabled
                    ? require('../../assets/images/toggle-active.svg')
                    : require('../../assets/images/toggle-inactive.svg')
                "
                alt=""
              />
              <p>{{ footerStatusText }}</p>
            </div>
          </div>
          <EditButton
            v-if="!footerEdit && user.isAdmin"
            @clicked="editFooter"
          />
        </div> -->
            <div class="language-selection">
              <div
                  v-if="user.access.company._id === 'xmwei_Rt9ryfpkxL4BL8'"
                  v-bind:class="{
                  'active-view': activeView === 'lv',
                  '': activeView !== 'lv',
                }"
                  @click="activeView = 'lv'"
                  id="latvianPicker"
              >
                <p class="content-small semi-bold">
                  {{ $t("LatvianLanguage") }}
                </p>
              </div>
              <div
                  v-bind:class="{
                  'active-view': activeView === 'et',
                  '': activeView !== 'et',
                }"
                  @click="activeView = 'et'"
                  id="estonianPicker"
              >
                <p class="content-small semi-bold">
                  {{ $t("EstonianLanguage") }}
                </p>
              </div>
              <div
                  v-bind:class="{
                  'active-view': activeView === 'eng',
                  '': activeView !== 'eng',
                }"
                  @click="activeView = 'eng'"
                  id="englishPicker"
              >
                <p class="content-small semi-bold">
                  {{ $t("EnglishLanguage") }}
                </p>
              </div>
              <div
                  v-bind:class="{
                  'active-view': activeView === 'ru',
                  '': activeView !== 'ru',
                }"
                  @click="activeView = 'ru'"
                  id="russianPicker"
              >
                <p class="content-small semi-bold">
                  {{ $t("RussianLanguage") }}
                </p>
              </div>
              <div
                  v-bind:class="{
                  'active-view': activeView === 'fi',
                  '': activeView !== 'fi',
                }"
                  @click="activeView = 'fi'"
                  id="finnishPicker"
              >
                <p class="content-small semi-bold">
                  {{ $t("FinnishLanguage") }}
                </p>
              </div>
            </div>
            <p
                class="grey900"
                v-if="(!footerText || !footerText[activeView]) && !footerEdit"
            >
              {{ $t("None") }}
            </p>
            <pre
                style="white-space: pre-wrap"
                v-if="!footerEdit && footerText && footerText[activeView]"
                class="content-normal"
                v-html="footerText[activeView]"
            ></pre>
            <textarea
                v-if="footerEdit && footerText"
                v-model="footerText[activeView]"
                id="textarea"
                name="textarea"
                rows="10"
            >
            </textarea>

            <div class="submit-cancel-wrapper">
              <CancelButton
                  v-if="footerEdit"
                  class="top-24"
                  @clicked="editFooter"
              />
              <SubmitButton
                  v-if="footerEdit"
                  class="top-24"
                  @clicked="saveFooter"
              />
            </div>
          </div>
        </BaseAccordion>
        <BaseAccordion
            :title="$t('CompanyLogo')"
            :parent-name="'settings'"
            :identifier="'companyLogo'"
        >
          <div style="display: flex; gap: 16px; justify-content: space-between">
            <div style="display: flex; height: fit-content; gap: 16px">
              <input
                  type="file"
                  ref="fileInput"
                  id="file-upload-input"
                  style="cursor: pointer"
                  @change="uploadCompanyLogo"
                  hidden
              />
              <div
                  @click="() => $refs.fileInput.click()"
                  style="
                  padding: 4px 8px;
                  cursor: pointer;
                  border: 1px dashed #c3c4c6;
                  height: 24px;
                  gap: 6px;
                  border-radius: 8px;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                    height="16"
                    :src="require('@/assets/images/image.svg')"
                    alt=""
                />
                <label style="width: 100%; cursor: pointer; font-size: 12px">{{
                    $t("Upload")
                  }}</label>
              </div>
              <p class="content-normal" v-if="selectedFile">
                {{ selectedFile.name }}
              </p>
              <p
                  class="content-normal"
                  style="color: #ff1e24"
                  v-if="errorMessage"
              >
                {{ $t(errorMessage) }} 260x56
              </p>
            </div>
            <img
                :key="'company-logo' + logoUpdater"
                v-if="company && company.companyLogo && company.companyLogo.name"
                style="
                width: 260px;
                height: 56px;
                background: #e6e8ec;
                object-fit: contain;
              "
                :src="company.companyLogo.name"
                alt=""
            />
          </div>
          <div
              style="
              margin-top: 24px;
              display: flex;
              flex-direction: column;
              gap: 8px;
            "
          >
            <p class="content-bold">
              {{ $t("PrioritizePrimaryListingImageFrom") }}:
            </p>
            <div
                @click="() => setCompanyListingPrioritization('top')"
                style="
                display: flex;
                cursor: pointer;
                gap: 8px;
                height: 24px;
                width: fit-content;
                align-items: center;
              "
            >
              <div style="width: 16px; display: flex; align-items: center">
                <img
                    v-if="
                    company &&
                    company.companyLogo &&
                    company.companyLogo.prioritization === 'top'
                  "
                    :src="require('@/assets/images/checkmark.svg')"
                    alt=""
                />
              </div>
              <p class="content-normal">{{ $t("top") }}</p>
            </div>
            <div
                @click="() => setCompanyListingPrioritization('center')"
                style="
                display: flex;
                cursor: pointer;
                gap: 8px;
                height: 24px;
                width: fit-content;
                align-items: center;
              "
            >
              <div style="width: 16px; display: flex; align-items: center">
                <img
                    v-if="
                    (company &&
                      company.companyLogo &&
                      company.companyLogo.prioritization === 'center') ||
                    !company ||
                    !company.companyLogo ||
                    !company.companyLogo.prioritization
                  "
                    :src="require('@/assets/images/checkmark.svg')"
                    alt=""
                />
              </div>
              <p class="content-normal">{{ $t("center") }}</p>
            </div>
            <div
                @click="() => setCompanyListingPrioritization('bottom')"
                style="
                display: flex;
                cursor: pointer;
                gap: 8px;
                height: 24px;
                width: fit-content;
                align-items: center;
              "
            >
              <div style="width: 16px; display: flex; align-items: center">
                <img
                    v-if="
                    company &&
                    company.companyLogo &&
                    company.companyLogo.prioritization === 'bottom'
                  "
                    :src="require('@/assets/images/checkmark.svg')"
                    alt=""
                />
              </div>
              <p class="content-normal">{{ $t("bottom") }}</p>
            </div>
          </div>
        </BaseAccordion>
      </BaseDetailviewContainer>
    </div>
  </div>
</template>

<script>
import SubmitButton from "@/components/common/SubmitButton";
// import WatermarkEditing from "@/components/Admin/WatermarkEditing";
import adminApi from "@/http/admin";
import CancelButton from "@/components/common/CancelButton";
import BaseInput from "@/components/common/BaseInput";
import BaseDropdown from "@/components/common/BaseDropdown";
import {mapActions, mapGetters} from "vuex";
import SharedResources from "@/components/Admin/SharedResources";
import BaseAccordion from "@/components/common/BaseAccordion";
import BaseDetailviewContainer from "@/components/common/BaseDetailviewContainer";
import axios from "axios";
import CorporateImageSection from "@/components/Admin/CorporateImageSection";
import BaseToggle from "@/components/common/BaseToggle";
import DropdownMulti from "@/components/common/DropdownMulti";

export default {
  name: "AdminSettingsContent",
  components: {
    DropdownMulti,
    BaseToggle,
    CorporateImageSection,
    // WatermarkEditing,
    SharedResources,
    CancelButton,
    SubmitButton,
    BaseInput,
    BaseDropdown,
    BaseAccordion,
    BaseDetailviewContainer,
  },
  data() {
    return {
      amlFeatureDropdownItems: [],
      selectedAmlDropdownItems: [],
      amlDropdownLoaded: false,
      logoUpdater: 0,
      image: [],
      showLogoPositionModal: false,
      company: null,
      loaded: false,
      footerEdit: false,
      activeView: "et",
      footerText: {
        et: "",
        eng: "",
        ru: "",
        fi: "",
        lv: ""
      },
      openAddSharedResource: 0,
      footerEnabled: false,
      activeDaysEdit: false,
      activeDays: 30,
      activeDaysEnabled: false,
      updateVisibilityValue: 0,
      visibilityValues: [
        {name: this.$t("Company"), value: "COMPANY"},
        {name: this.$t("Group"), value: "GROUP"},
        {name: this.$t("Private+"), value: "PRIVATE+"},
        {name: this.$t("Private"), value: "PRIVATE"},
      ],
      visibilityValue: "",
      visibilityEdit: false,
      visibilityLimitable: true,
      listingCorporateImageStatus: false,
      listingsCorporateImageEdit: false,
      selectedFile: null,
      errorMessage: null,
    };
  },
  created() {
    if (this.user.access.company._id === 'xmwei_Rt9ryfpkxL4BL8') {
      this.footerText = {
        et: "",
        eng: "",
        ru: "",
        lv: ""
      }
      this.activeView = "lv"
    } else {
      this.footerText = {
        et: "",
        eng: "",
        ru: "",
        fi: ""
      }
    }
    this.setup();
  },
  computed: {
    ...mapGetters(["user", "adminSettingsAccordionsStates"]),
    footerStatusText() {
      if (this.footerEnabled) {
        return this.$t("Active");
      } else {
        return this.$t("Inactive");
      }
    },
    activeDaysStatusText() {
      if (this.activeDaysEnabled) {
        return this.$t("Active");
      } else {
        return this.$t("Inactive");
      }
    },
  },
  methods: {
    ...mapActions(["setAdminSettingsAccordionState"]),
    async changePolicyFilter(field, value) {
      const policyFilter = this.user.companyPolicies.filters;
      policyFilter[field].showAll = value;
      await adminApi.savePolicyFilter(policyFilter);
    },
    async setup() {
      await Promise.all([
        this.getFooter(),
        this.getActiveDays(),
        this.getCompanyInfo(),
        this.getVisibilityPolicies(),
      ]);
      if (this.company.outputLinks && this.company.outputLinks.www) {
        this.getCompanyWwwLink();
      }
      this.loaded = true;
    },
    showOkidoki(){
      return this.company.marketplaces.some(marketplace => marketplace.name === "okidoki")
    },
    showSS(){
      return this.company.marketplaces.some(marketplace => marketplace.name === "ss")
    },
    showChristies(){
      return this.company.marketplaces.some(marketplace => marketplace.name === "christies")
    },
    getCompanyWwwLink() {
      const currentPath = window.location.href;
      const currentEnv = currentPath.indexOf("/admin");
      const getCurrentEnv = currentPath.substring(0, currentEnv);

      const outputLink = this.company.outputLinks.www;
      const landerLink = outputLink.indexOf("/company-listings");
      const getLanderLink = outputLink.substring(landerLink, outputLink.length);

      const newPath = getCurrentEnv + getLanderLink;
      return newPath;
    },
    async getCompanyInfo() {
      const companyId = this.user.companyId;
      const response = await axios.get(`/api/company/info/${companyId}`);
      console.log(response.data)
      this.company = response.data;
      this.logoUpdater++;
      if (this.company.listings.corporateImageEnabled) {
        this.listingCorporateImageStatus =
            this.company.listings.corporateImageEnabled;
      }
      this.amlFeatureToDropdown();
    },
    async getVisibilityPolicies() {
      const policies = await adminApi.getPolicies();
      if (policies.policies) {
        this.visibilityValue = policies.policies.visibility.default_visibility;
        if (policies.policies.visibility.limitable.customers) {
          this.visibilityLimitable = true;
        } else {
          this.visibilityLimitable = false;
        }
      }
    },
    async getFooter() {
      const footer = await adminApi.getFooterText();
      if (footer.footerText) {
        this.footerText = footer.footerText;
      }
      this.footerEnabled = footer.footerEnabled;
    },
    editFooter() {
      this.footerEdit = !this.footerEdit;
      if (!this.footerEdit) {
        this.getFooter();
      }
    },
    async editVisibility() {
      this.visibilityEdit = !this.visibilityEdit;
      if (!this.visibilityEdit) {
        await this.getVisibilityPolicies();
        this.updateVisibilityValue++;
      }
    },
    async saveFooter() {
      try {
        await adminApi.saveFooterText(this.footerText);
        this.toastSuccess(
            this.$t("Footer") + " " + this.$t("Updated").toLowerCase()
        );
        this.footerEdit = false;
      } catch (e) {
        this.toastError(this.$t("ErrorUpdating"));
      }
    },
    async saveVisibility() {
      try {
        const visibilityData = {
          visibilityValue: this.visibilityValue,
          visibilityLimitable: this.visibilityLimitable,
        };
        await adminApi.saveVisibility(visibilityData);
        this.toastSuccess(
            this.$t("Policy") + " " + this.$t("Updated").toLowerCase()
        );
        this.visibilityEdit = false;
      } catch (e) {
        this.toastError(this.$t("ErrorUpdating"));
      }
    },
    async toggleUserVisibility() {
      try {
        if (!this.user.isAdmin) return;
        this.visibilityLimitable = !this.visibilityLimitable;
        this.saveVisibility();
      } catch (e) {
        this.toastError(this.$t("ErrorUpdating"));
      }
    },
    async toggleFooterStatus() {
      try {
        if (!this.user.isAdmin) return;
        this.footerEnabled = !this.footerEnabled;
        await adminApi.toggleFooterStatus(this.footerEnabled);
        this.toastSuccess(
            this.$t("Footer") + " " + this.$t("Updated").toLowerCase()
        );
      } catch (e) {
        this.toastError(this.$t("ErrorUpdating"));
      }
    },
    async getActiveDays() {
      const activeDaysData = await adminApi.getActiveDays();
      this.activeDays = activeDaysData.activeDays;
      this.activeDaysEnabled = activeDaysData.activeDaysEnabled;
    },
    editActiveDays() {
      this.activeDaysEdit = !this.activeDaysEdit;
      if (!this.activeDaysEdit) {
        this.getActiveDays();
      }
    },
    editListingsCorporateImage() {
      this.listingsCorporateImageEdit = !this.listingsCorporateImageEdit;
    },
    async saveActiveDays() {
      try {
        await adminApi.saveActiveDays(this.activeDays);
        this.toastSuccess(
            this.$t("ListingsDaysActive") + " " + this.$t("Updated").toLowerCase()
        );
        this.activeDaysEdit = false;
      } catch (e) {
        this.toastError(this.$t("ErrorUpdating"));
      }
    },
    async toggleActiveDaysStatus() {
      try {
        if (!this.user.isAdmin) return;
        this.activeDaysEnabled = !this.activeDaysEnabled;
        await adminApi.toggleActiveDaysStatus(this.activeDaysEnabled);
        this.toastSuccess(
            this.$t("ListingsDaysActive") + " " + this.$t("Updated").toLowerCase()
        );
      } catch (e) {
        this.toastError(this.$t("ErrorUpdating"));
      }
    },
    openWaterMarkModal() {
      this.showLogoPositionModal = true;
    },
    async toggleListingCorporateImageStatus() {
      try {
        this.listingCorporateImageStatus = !this.listingCorporateImageStatus;
        await adminApi.toggleCorporateImageStatus(
            this.listingCorporateImageStatus
        );
        this.toastSuccess(this.$t("CorporateImageStatusSaved"));
      } catch (e) {
        this.toastError(this.$t("ErrorUpdating"));
      }
    },
    async uploadCompanyLogo(event) {
      if (event && event.target && event.target.files[0]) {
        const imageFile = event.target.files[0];
        if (
            imageFile.type === "image/jpeg" ||
            imageFile.type === "image/png" ||
            imageFile.type === "image/svg+xml"
        ) {
          const imageBase64 = await this.imageToBase64(imageFile);
          this.selectedFile = imageFile;
          await axios
              .post("/api/company/logo", {imageBase64: imageBase64})
              .then((response) => {
                if (response && response.status === 200) {
                  this.toastSuccess(this.$t("Uploaded"));
                  this.getCompanyInfo();
                }
              });
        }
      }
    },
    getImageSize(file) {
      const img = document.createElement("img");
      const promise = new Promise((resolve, reject) => {
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          resolve({width, height});
        };

        img.onerror = reject;
      });
      img.src = window.URL.createObjectURL(file);
      console.log(img.src);

      return promise;
    },
    imageToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async setCompanyListingPrioritization(location) {
      await axios
          .post("/api/company/image-prioritization", {location: location})
          .then((response) => {
            console.log(response);
            if (response && response.status === 200) {
              this.toastSuccess(this.$t("Updated"));
              this.getCompanyInfo();
            }
          });
    },
    amlFeatureToDropdown() {
      if (this.company.access.features?.modules?.listings?.features?.aml?.enabled) {
        this.amlFeatureDropdownItems = [];
        for (const field in this.company.access.features.modules.listings.features.aml.features) {
          const dropdownItem = {
            name: this.company.access.features.modules.listings.features.aml.features[field].translation || this.$t(field),
            value: field
          }
          this.amlFeatureDropdownItems.push(dropdownItem)
          if (this.company.access.features.modules.listings.features.aml.features[field].enabled) {
            this.selectedAmlDropdownItems.push(dropdownItem)
          }
        }
      }
      this.amlDropdownLoaded = true;
    },
    async amlValueChanged(value) {
      if (!this.amlDropdownLoaded) return;
      // Validate if changed
      let sendUpdate = false;
      if (this.company.access.features?.modules?.listings?.features?.aml?.enabled) {
        for (const field in this.company.access.features.modules.listings.features.aml.features) {
          const fieldSelected = value.find((dropdownValue) => dropdownValue.value === field);
          if ((this.company.access.features.modules.listings.features.aml.features[field].enabled && !fieldSelected) || (!this.company.access.features.modules.listings.features.aml.features[field].enabled && fieldSelected)) {
            // update
            sendUpdate = true;
          }
        }
      }
      if (sendUpdate) {
        const response = await axios.post("/api/admin/company/feature", {feature: "listings.aml", value: value});
        if (response && response.status === 200) {
          this.toastSuccess(this.$t("Updated"));
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  padding-bottom: 32px;
}

#settings-content {
  display: flex;
  flex-direction: column;
  max-width: 680px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 32px;
  padding-top: 32px;
  padding-bottom: 0px;
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

#textarea {
  min-height: fit-content;
}

.submit-cancel-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.toggle-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.header-toggle-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  width: 100%;
}

.section-separator {
  position: relative;
  width: 100%;
  height: 1px;

  /* grey/grey500 */

  background: #e6e8ec;

  /* Inside auto layout */

  flex: none;
  margin-top: 24px;
}

.xml-data {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 12px;
}

.language-selection {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-bottom: 24px;

  div {
    cursor: pointer;
  }
}

.active-view {
  padding-bottom: 18px;
  border-bottom: 1px solid black;
}

.visibility-default-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-wrap: nowrap;
  align-items: center;
}

.setting-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

::v-deep .imgsPreview .imageHolder {
  display: none;
}
</style>
