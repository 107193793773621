var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "custom-container"
  }, [_c('div', {
    staticClass: "custom-table"
  }, [_c('div', {
    staticClass: "custom-table-header"
  }, _vm._l(_vm.columns, function (column, colIndex) {
    return _c('div', {
      key: 'table-column-' + colIndex,
      staticClass: "label12",
      style: Object.assign({}, column.style, _vm.sortField === column.field && (_vm.sortOrder === 1 || _vm.sortOrder === -1) ? {
        fontWeight: '700'
      } : ''),
      on: {
        "click": function ($event) {
          return _vm.sortBy(column.field);
        }
      }
    }, [_c('p', [_vm._v(_vm._s(_vm.$t(`${column.title}`)))]), _vm.sortField === column.field ? _c('span', {
      staticStyle: {
        "height": "16px",
        "width": "16px"
      }
    }, [_vm.sortOrder === 1 ? _c('img', {
      attrs: {
        "width": "16",
        "height": "16",
        "src": require('@/assets/images/direction=up.svg'),
        "alt": ""
      }
    }) : _vm.sortOrder === -1 ? _c('img', {
      attrs: {
        "width": "16",
        "height": "16",
        "src": require('@/assets/images/direction=down.svg'),
        "alt": ""
      }
    }) : _vm._e()]) : _vm._e()]);
  }), 0), _vm.draggable ? _c('draggable', {
    staticClass: "custom-table-body",
    attrs: {
      "forceFallback": true,
      "list": _vm.sortedRows,
      "animation": 200,
      "tag": "div",
      "handle": ".handle"
    },
    on: {
      "start": function (e) {
        return _vm.dragStart(e);
      },
      "end": function (e) {
        return _vm.dragEnd(e);
      }
    }
  }, _vm._l(_vm.sortedRows, function (_ref, rowIndex) {
    var rowValue = _ref[1];
    return _c('div', {
      key: 'table-row-' + rowIndex,
      staticClass: "custom-table-row"
    }, _vm._l(_vm.columns, function (column, colIndex) {
      return _c('div', {
        key: 'table-cell-' + rowIndex + '-' + colIndex,
        staticClass: "table-cell",
        class: {
          handle: !column.type
        },
        style: Object.assign({}, column.style),
        on: {
          "click": function ($event) {
            return _vm.editField(column, rowIndex, colIndex);
          }
        }
      }, [column.type && (_vm.editingField.colIndex !== colIndex || _vm.editingField.rowIndex !== rowIndex) ? _c('div', {
        staticClass: "table-edit-field"
      }, [_c('button', {
        ref: 'table-button-' + rowIndex + '-' + colIndex,
        refInFor: true,
        staticClass: "content-normal",
        on: {
          "focus": function ($event) {
            return _vm.editField(column, rowIndex, colIndex);
          }
        }
      }, [_vm._v(" " + _vm._s(rowValue[column.field] || column.emptyValue) + " ")])]) : _vm.editingField.colIndex === colIndex && _vm.editingField.rowIndex === rowIndex ? _c('textarea', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: rowValue[column.field],
          expression: "rowValue[column.field]"
        }],
        ref: 'table-edit-' + rowIndex + '-' + colIndex,
        refInFor: true,
        staticClass: "table-textarea",
        domProps: {
          "value": rowValue[column.field]
        },
        on: {
          "blur": function ($event) {
            return _vm.closeEditField(rowIndex);
          },
          "keydown": [function ($event) {
            if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
            return function (e) {
              return _vm.blurEditField(e, rowIndex, colIndex, false);
            }.apply(null, arguments);
          }, function ($event) {
            if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
            return function (e) {
              return _vm.blurEditField(e, rowIndex, colIndex);
            }.apply(null, arguments);
          }],
          "input": function ($event) {
            if ($event.target.composing) return;
            _vm.$set(rowValue, column.field, $event.target.value);
          }
        }
      }) : _c('p', {
        staticClass: "content-normal"
      }, [_vm._v(" " + _vm._s(rowValue[column.field]) + " ")])]);
    }), 0);
  }), 0) : _c('div', {
    staticClass: "custom-table-body"
  }, _vm._l(_vm.paginatedRows, function (row, rowIndex) {
    return _c('div', {
      key: 'table-row-' + rowIndex,
      staticClass: "custom-table-row"
    }, _vm._l(_vm.columns, function (column, colIndex) {
      return _c('div', {
        key: 'table-cell-' + rowIndex + '-' + colIndex,
        staticClass: "custom-table-cell",
        style: Object.assign({}, column.style)
      }, [_vm._v(" " + _vm._s(row[column.field]) + " ")]);
    }), 0);
  }), 0)], 1), _c('div', {
    staticClass: "add-user-buttons"
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.$emit('closed');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('div', {
    staticClass: "add-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('updated');
      }
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "9px"
    },
    attrs: {
      "alt": "",
      "src": require("../../assets/images/checkmarkCircled.svg")
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("Submit")))])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }