<template>
  <div class="table-row-wrapper" @click="$emit('click', rowData)">
    <div :style="column.style" v-for="column in columns" :key="column.field">
      <TableTextInput v-model="rowData[column.field].value[column.table.editField]"
                      :ref="`tableTextInput-${rowData._id}-${column.field}`" @blur="exitRowEdit(column)"
                      v-if="column.table?.editing?._id === rowData._id"/>
      <p v-else :class="{'table-row-editable': column.table?.editable && rowData[column.field]?.value && accessOverUser}" @click="(e) => editRow(e, column)" class="content-normal"
         v-html="formatRowData(column)"></p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TableTextInput from "@/components/TableTextInput";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "TableRow",
  components: {TableTextInput},
  props: ["columns", "rowData"],
  computed: {
    ...mapGetters(["user"]),
    accessOverUser() {
      if (this.user.access.accessLevel === "company_admin") {
        return true;
      } else if (this.user.access.accessLevel === "group_admin" && this.user.access.users[this.rowData._id]) {
        return true;
      }
      return false;
    }
  },
  data() {
    return {
      commands: {
        'JOIN_BY_COMMA_TRANSLATED': (arrayOfStrings) => {
          let array = arrayOfStrings || []
          let returnedString = "";
          for (let i = 0; i < array.length; i++) {
            returnedString += this.$t(arrayOfStrings[i]);
            if (i + 1 !== array.length) {
              returnedString += ", "
            }
          }
          return returnedString
        },
        'JOIN_BY_COMMA': (arrayOfStrings) => {
          return arrayOfStrings.join(", ");
        },
        'DATE': (date) => {
          return moment(date).format('hh:mm DD.MM.YYYY')
        },
        'USER_ACCESS_LEVEL': (accessLevel) => {
          switch (accessLevel) {
            case "company_admin":
              return this.$t('Admin');
            case "group_admin":
              return this.$t('GroupAdmin');
            default:
              return this.$t('User');
          }
        },
        'MARKETPLACES': (marketplace) => {
          return marketplace?.display;
        }
      }
    }
  },
  methods: {
    formatRowData(column) {
      const command = column.command?.table;
      if (command) {
        return this.commands[command](this.rowData[column.field]);
      }
      return this.rowData[column.field];
    },
    editRow(e, column) {
      if (column.table?.editable && this.rowData[column.field]?.value && this.accessOverUser) {
        e.stopPropagation();
        column.table.editing._id = this.rowData._id;
        this.$nextTick(() => {
          if (this.$refs[`tableTextInput-${this.rowData._id}-${column.field}`] && this.$refs[`tableTextInput-${this.rowData._id}-${column.field}`][0]) {
            this.$refs[`tableTextInput-${this.rowData._id}-${column.field}`][0].focus();
          }
        });
      }
    },
    async exitRowEdit(column) {
      if (column.table) {
        column.table.editing._id = null;
        await this.makeRequest(column.table.endpoint)
      }
    },
    async makeRequest(endpoint) {
      const body = {};
      for (const [field, path] of Object.entries(endpoint.body)) {
        if (typeof path === 'string') {
          const pathArray = path.split(".");
          if (pathArray.length > 1) {
            let fieldValue = this.rowData;
            for (const path of pathArray) {
              fieldValue = fieldValue[path]
            }
            body[field] = fieldValue;
          } else {
            body[field] = this.rowData[field];
          }
        } else {
          body[field] = {};
          for (const [nestedField, nestedPath] of Object.entries(endpoint.body[field])) {
            const nestedPathArray = nestedPath.split(".");
            if (nestedPathArray.length > 1) {
              let fieldValue = this.rowData;
              for (const path of nestedPathArray) {
                fieldValue = fieldValue[path]
              }
              body[field][nestedField] = {value: fieldValue, path: nestedPath};
            } else {
              body[field][nestedField] = this.rowData[nestedField];
            }
          }
        }
      }
      const response = await axios({
        method: endpoint.method,
        url: endpoint.url,
        data: {meta: endpoint.meta, ...body}
      });
      if (response && response.status === 200) {
        // toast success
        this.toastSuccess(this.$t("Updated"))
      } else {
        this.toastError(this.$t("ErrorUpdating"))
        // toast fail
      }
      this.$emit("reload");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/global_variables.scss";

.table-row-wrapper {
  display: flex;
  background-color: white;
  cursor: pointer;
}

.table-row-wrapper div {
  display: flex;
  align-items: center;
}

.table-row-wrapper:last-child {
  border-radius: 0 0 8px 8px;
}

.table-row-wrapper:not(:last-child) {
  border-bottom: 1px solid $grey;
}

.table-row-wrapper:hover {
  background-color: $light-grey;
}

.table-row-wrapper p {
  padding: 12px 16px;
  min-height: 60px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.table-row-editable:hover {
  background-color: $grey;
  border-radius: 8px;
}

</style>