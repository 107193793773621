var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-header",
    attrs: {
      "name": "table header"
    }
  }, _vm._l(_vm.columns, function (column) {
    return _c('div', {
      key: column.translation + column.sort
    }, [column.sort === 1 || column.sort === -1 ? _c('div', {
      staticStyle: {
        "padding": "12px 16px",
        "display": "flex",
        "height": "100%",
        "width": "100%"
      },
      style: column.style,
      on: {
        "click": function ($event) {
          return _vm.sortByColumn(column, column.sort === 1 ? -1 : 0);
        }
      }
    }, [_c('p', {
      staticClass: "content-small-bold"
    }, [_vm._v(_vm._s(_vm.$t(column.translation)))]), _c('img', {
      attrs: {
        "height": "16",
        "width": "16",
        "src": require(`../assets/images/${column.sort === 1 ? 'direction=up.svg' : 'direction=down.svg'}`),
        "alt": "d"
      }
    })]) : _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "padding": "12px 16px"
      },
      style: column.style,
      on: {
        "click": function ($event) {
          return _vm.sortByColumn(column, 1);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(column.translation)))]), _c('p')]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }