<template>
  <div>
    <div v-if="sharedResources.length > 0 || resourceAdd.open" :key="'resources-' + resourcesUpdater"
         class="shared-resources-container">
      <div v-for="sharedResource of sharedResources" :key="sharedResource._id" class="shared-resource">
        <template v-if="sharedResource._id !== resourceEdit._id">
          <div class="shared-resource-text">
            <div class="content-semibold">{{ sharedResource.name }}</div>
            <div class="content-small">{{ sharedResource.description }}</div>
          </div>
          <BaseButton @itemSelected="(itemPicked) => sharedResourceDropdownItemSelected(itemPicked, sharedResource)"
                      size="icon" :display-key="'name'"
                      :display-value="'value'" :items="sharedResourceDropdownItems"
                      :icon-left="'dot_menu_gray_20_20.svg'"/>
        </template>
        <template v-if="resourceEdit._id === sharedResource._id">
          <div class="shared-resource-edit-container">
            <BaseInput :placeholder="$t('ResourceName')" :required="true" :error="editError.showError"
                       :error-message="editError.message" v-model="resourceEdit.name"/>
            <BaseTextarea :placeholder="$t('Description')" v-model="resourceEdit.description"/>
            <BaseDropdown v-model="resourceEdit.visibleTo" :items="visibilityValues" :placeholder="$t('VisibleTo')"
                          :dropdown-allow-null="false"
                          :show-dropdown-arrow="true"/>
            <DropdownMulti
                v-if="resourceEdit.visibleTo ==='GROUP'"
                :items="groups"
                :selected-items="resourceEdit.groupIds"
                :height="'52px'"
                :display-key="'name'"
                :value-key="'value'"
                :field-type="$t('Group')"
                :required="true"
                :enable-search="true"
                :error="errors && resourceEdit.groupIds.length < 1"
                @input="updateSelectedItems"
                @update:validity="handleValidity"
            />
            <div class="add-container-buttons">
              <BaseButton @click="closeEditSharedResource" size="large" :label="$t('Cancel')"/>
              <BaseButton @click="editSharedResource" size="large" :icon-left="'checkmark_white_16_16.svg'"
                          primary="true"
                          :label="$t('Submit')"/>
            </div>
          </div>
        </template>
      </div>
      <div v-if="resourceAdd.open" class="shared-resource-add-container">
        <BaseInput :placeholder="$t('ResourceName')" :required="true" :error="addError.showError"
                   :error-message="addError.message" v-model="resourceAdd.name"/>
        <BaseTextarea :placeholder="$t('Description')" v-model="resourceAdd.description"/>
        <BaseDropdown v-model="resourceAdd.visibleTo" :items="visibilityValues" :placeholder="$t('VisibleTo')"
                      :dropdown-allow-null="false"
                      :show-dropdown-arrow="true"/>
        <DropdownMulti
            v-if="resourceAdd.visibleTo ==='GROUP'"
            :items="groups"
            :selected-items="resourceAdd.groupIds"
            :height="'52px'"
            :display-key="'name'"
            :value-key="'value'"
            :field-type="$t('Group')"
            :required="true"
            :enable-search="true"
            :error="errors && errors.groups === 'FieldRequired' && resourceAdd.groupIds.length < 1"
            @input="updateResourceAddSelectedItems"
            @update:validity="handleValidity"
        />
        <div class="add-container-buttons">
          <BaseButton @click="closeAddSharedResource" size="large" :label="$t('Cancel')"/>
          <BaseButton @click="addSharedResource" size="large" :icon-left="'checkmark_white_16_16.svg'" primary="true"
                      :label="$t('Submit')"/>
        </div>
      </div>
    </div>
    <p v-if="sharedResources.length === 0 && !resourceAdd.open" class="no-content">{{
        $t("NoActiveSharedResources")
      }}</p>
  </div>
</template>

<script>
import BaseButton from "@/components/common/BaseButton";
import BaseInput from "@/components/common/BaseInput";
import BaseTextarea from "@/components/common/BaseTextarea";
import BaseDropdown from "@/components/common/BaseDropdown";
import adminApi from "@/http/admin";
import {mapGetters} from "vuex";
import axios from "axios";
import DropdownMulti from "@/components/common/DropdownMulti";

export default {
  name: "SharedResources",
  components: {DropdownMulti, BaseTextarea, BaseInput, BaseButton, BaseDropdown},
  props: [
    "addResource"
  ],
  watch: {
    "addResource": function () {
      if (this.resourceEdit._id) {
        this.closeEditSharedResource();
      }
      this.resourceAdd.open = true;
    },
    "resourceAdd.name": function () {
      if (!this.addError.showError) {
        return;
      }
      this.addError.showError = false;
    },
    "resourceEdit.name": function () {
      if (!this.editError.showError) {
        return;
      }
      this.editError.showError = false;
    },
    "resourceAdd.groupIds": function () {
      if (!this.addError.showError) {
        return;
      }
      this.addError.showError = false;
    },
    "resourceEdit.groupIds": function () {
      if (!this.editError.showError) {
        return;
      }
      this.editError.showError = false;
    },
    "resourceEdit.visibleTo": function (newValue, prevValue) {
      if (newValue === "GROUP" && prevValue === "COMPANY") {
        this.resourceEdit.groupIds = this.user.groups.map((group) => {
          return {name: group.name, value: group._id}
        })
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      groups: [],
      sharedResources: [],
      resourcesUpdater: 0,
      resourceAdd: {
        open: false,
        name: "",
        description: "",
        groupIds: [],
        visibleTo: "GROUP",
      },
      validate: [
        "name",
        "groups",
      ],
      addError: {
        showError: false,
        message: "",
      },
      resourceEdit: {
        name: "",
        description: "",
        visibleTo: "",
        groupIds: [],
        _id: "",
      },
      editError: {
        showError: false,
        message: "",
      },
      sharedResourceDropdownItems: [
        {name: this.$t("Edit"), value: "EDIT"},
        {name: this.$t("Delete"), value: "DELETE", showConfirmModal: true},
      ],
      visibilityValues: [
        {name: this.$t("Company"), value: "COMPANY"},
        {name: this.$t("Group"), value: "GROUP"},
      ],
      multiSelectValidity: true,
      errors: null
    }
  },
  created() {
    this.getSharedResources()
    this.getGroups()
  },
  methods: {
    selectAddGroups(selectedGroups) {
      this.resourceAdd.groupIds = [];
      selectedGroups.forEach(group => this.resourceAdd.groupIds.push(group))
    },
    selectEditGroups(selectedGroups) {
      this.resourceEdit.groupIds = [];
      selectedGroups.forEach(group => this.resourceEdit.groupIds.push(group))
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();
      return this.validate
        .map((field) => {
          console.log(field)
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    getEmptyErrors() {
      return {
        name: "",
        groups: [],
      };
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "groups":
          if (!this.multiSelectValidity && ((this.resourceAdd && this.resourceAdd.visibleTo === 'GROUP') || (this.resourceEdit && this.resourceEdit.visibleTo === 'GROUP'))) {
            this.errors[fieldName] = "FieldRequired";
            return false;
          }
          break;
      }
      return true;
    },
    async getGroups() {
      const response = await axios.get(
          `/api/get-groups/${this.user.companyId}`
      );
      if (response && response.data) {
        let groups = []
        for (const groupId in response.data.access.groups) {
            groups.push({name: response.data.access.groups[groupId].name, value: groupId})
        }
        groups.sort(function(a, b) {
            var textA = a.name.toLowerCase();
            var textB = b.name.toLowerCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.groups = groups
      }
      this.resourceAdd.groupIds = []
    },
    async getSharedResources() {
      this.sharedResources = await adminApi.getSharedResources();
      this.resourcesUpdater++;
    },
    async sharedResourceDropdownItemSelected(itemPicked, sharedResource) {
      console.log("item picked:", itemPicked, sharedResource);
      switch (itemPicked.value) {
        case "EDIT":
          this.resourceEdit.name = sharedResource.name;
          this.resourceEdit.description = sharedResource.description;
          this.resourceEdit.visibleTo = sharedResource.visibleTo;
          if (sharedResource.visibleTo === "GROUP") {
            this.resourceEdit.groupIds = this.groups.filter((group) => sharedResource.groupIds.includes(group.value))
          }
          this.resourceEdit._id = sharedResource._id;
          this.resourceEdit.statusCode = 1;
          if (this.resourceAdd.open) {
            this.closeAddSharedResource();
          }
          break;
        case "DELETE":
          await adminApi.deleteSharedResource(sharedResource);
          await this.getSharedResources();
          break;
      }
    },
    closeEditSharedResource() {
      this.resourceEdit = {
        name: "",
        description: "",
        visibleTo: "",
        groupIds: [],
        _id: "",
      }
    },
    async editSharedResource() {
      if (!this.isFormValid()) {
        this.showErrors = true;
        return;
      }

      // if (this.resourceEdit.name === "" || /^\s*$/.test(this.resourceEdit.name)) {
      //   this.addError = {
      //     showError: true,
      //     message: this.$t("FormErrors.FieldRequired")
      //   }
      //   return;
      // }

      if (this.resourceEdit.visibleTo === "GROUP" && (!this.resourceEdit.groupIds || this.resourceEdit.groupIds.length === 0 || !this.multiSelectValidity)) {
        this.addError = {
          showError: true,
          message: this.$t("FormErrors.FieldRequired")
        }
        return;
      }

      this.resourceEdit.groupIds = this.resourceEdit.groupIds.map((item) => {return item['value']})
      console.log(this.resourceEdit.groupIds)
      await adminApi.editSharedResource(this.resourceEdit);
      this.closeEditSharedResource();
      await this.getSharedResources();
    },
    closeAddSharedResource() {
      this.resourceAdd = {
        open: false,
        name: "",
        description: "",
        visibleTo: "GROUP",
        groupIds: [],
      }
      this.resourceAdd.groupIds = []
    },
    async addSharedResource() {
      if (!this.isFormValid()) {
        this.showErrors = true;
        return;
      }
      if (this.resourceAdd.name === "" || /^\s*$/.test(this.resourceAdd.name)) {
        this.addError = {
          showError: true,
          message: this.$t("FormErrors.FieldRequired")
        }
        return;
      }

      if (this.resourceAdd.visibleTo === "GROUP" && (!this.resourceAdd.groupIds || this.resourceAdd.groupIds.length === 0 || !this.multiSelectValidity)) {
        this.addError = {
          showError: true,
          message: this.$t("FormErrors.FieldRequired")
        }
        return;
      }

      this.resourceAdd.groupIds = this.resourceAdd.groupIds.map((item) => {return item['value']})

      await adminApi.addSharedResource(this.resourceAdd);
      this.closeAddSharedResource();
      await this.getSharedResources();
    },
    updateSelectedItems(selectedItems) {
      this.resourceEdit.groupIds = selectedItems;
    },    
    updateResourceAddSelectedItems(selectedItems) {
      this.resourceAdd.groupIds = selectedItems
    },
    handleValidity(validity) {
      this.multiSelectValidity = validity;
    }
  }
}
</script>

<style scoped>
.shared-resources-container {
  border: 1px solid #E6E8EC;
  border-radius: 8px;
  width: 100%;
}

.shared-resource {
  width: 100%;
  padding: 11px;
  display: flex;
  gap: 4px;
  justify-content: space-between;
}

.shared-resource:not(:last-child) {
  border-bottom: 1px solid #E6E8EC;
}

.shared-resource-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 40px;
}

.shared-resource-add-container {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.shared-resource-edit-container {
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.add-container-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.no-content {
  color: #75787a;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

</style>