<template>
    <textarea
        class="table-textarea content-normal"
        ref="textarea"
        :value="value"
        @blur="$emit('blur')"
        @input="$emit('input', $event.target.value)"
        @keyup.esc="blur"
        @keyup.enter="blur"
        @focus="selectText"
    />
</template>

<script>
export default {
  name: "TableTextInput",
  props: ['value'], // Accepts 'value' prop for v-model binding
  methods: {
    focus() {
      this.$refs.textarea.focus();
    },
    blur() {
      this.$refs.textarea.blur();
    },
    selectText() {
      this.$refs.textarea.select();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.table-textarea {
  outline: none;
  resize: none;
  overflow: hidden;
  height: 36px;
  width: 100%;
  line-height: 36px;
}

.table-textarea:focus {
  padding: 0 4px;
  border-bottom: 2px solid #16afb9;
  background-color: $light-grey;
}
</style>