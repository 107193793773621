var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('textarea', {
    ref: "textarea",
    staticClass: "table-textarea content-normal",
    domProps: {
      "value": _vm.value
    },
    on: {
      "blur": function ($event) {
        return _vm.$emit('blur');
      },
      "input": function ($event) {
        return _vm.$emit('input', $event.target.value);
      },
      "keyup": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.blur.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.blur.apply(null, arguments);
      }],
      "focus": _vm.selectText
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }