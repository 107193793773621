var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.sharedResources.length > 0 || _vm.resourceAdd.open ? _c('div', {
    key: 'resources-' + _vm.resourcesUpdater,
    staticClass: "shared-resources-container"
  }, [_vm._l(_vm.sharedResources, function (sharedResource) {
    return _c('div', {
      key: sharedResource._id,
      staticClass: "shared-resource"
    }, [sharedResource._id !== _vm.resourceEdit._id ? [_c('div', {
      staticClass: "shared-resource-text"
    }, [_c('div', {
      staticClass: "content-semibold"
    }, [_vm._v(_vm._s(sharedResource.name))]), _c('div', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(sharedResource.description))])]), _c('BaseButton', {
      attrs: {
        "size": "icon",
        "display-key": 'name',
        "display-value": 'value',
        "items": _vm.sharedResourceDropdownItems,
        "icon-left": 'dot_menu_gray_20_20.svg'
      },
      on: {
        "itemSelected": function (itemPicked) {
          return _vm.sharedResourceDropdownItemSelected(itemPicked, sharedResource);
        }
      }
    })] : _vm._e(), _vm.resourceEdit._id === sharedResource._id ? [_c('div', {
      staticClass: "shared-resource-edit-container"
    }, [_c('BaseInput', {
      attrs: {
        "placeholder": _vm.$t('ResourceName'),
        "required": true,
        "error": _vm.editError.showError,
        "error-message": _vm.editError.message
      },
      model: {
        value: _vm.resourceEdit.name,
        callback: function ($$v) {
          _vm.$set(_vm.resourceEdit, "name", $$v);
        },
        expression: "resourceEdit.name"
      }
    }), _c('BaseTextarea', {
      attrs: {
        "placeholder": _vm.$t('Description')
      },
      model: {
        value: _vm.resourceEdit.description,
        callback: function ($$v) {
          _vm.$set(_vm.resourceEdit, "description", $$v);
        },
        expression: "resourceEdit.description"
      }
    }), _c('BaseDropdown', {
      attrs: {
        "items": _vm.visibilityValues,
        "placeholder": _vm.$t('VisibleTo'),
        "dropdown-allow-null": false,
        "show-dropdown-arrow": true
      },
      model: {
        value: _vm.resourceEdit.visibleTo,
        callback: function ($$v) {
          _vm.$set(_vm.resourceEdit, "visibleTo", $$v);
        },
        expression: "resourceEdit.visibleTo"
      }
    }), _vm.resourceEdit.visibleTo === 'GROUP' ? _c('DropdownMulti', {
      attrs: {
        "items": _vm.groups,
        "selected-items": _vm.resourceEdit.groupIds,
        "height": '52px',
        "display-key": 'name',
        "value-key": 'value',
        "field-type": _vm.$t('Group'),
        "required": true,
        "enable-search": true,
        "error": _vm.errors && _vm.resourceEdit.groupIds.length < 1
      },
      on: {
        "input": _vm.updateSelectedItems,
        "update:validity": _vm.handleValidity
      }
    }) : _vm._e(), _c('div', {
      staticClass: "add-container-buttons"
    }, [_c('BaseButton', {
      attrs: {
        "size": "large",
        "label": _vm.$t('Cancel')
      },
      on: {
        "click": _vm.closeEditSharedResource
      }
    }), _c('BaseButton', {
      attrs: {
        "size": "large",
        "icon-left": 'checkmark_white_16_16.svg',
        "primary": "true",
        "label": _vm.$t('Submit')
      },
      on: {
        "click": _vm.editSharedResource
      }
    })], 1)], 1)] : _vm._e()], 2);
  }), _vm.resourceAdd.open ? _c('div', {
    staticClass: "shared-resource-add-container"
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('ResourceName'),
      "required": true,
      "error": _vm.addError.showError,
      "error-message": _vm.addError.message
    },
    model: {
      value: _vm.resourceAdd.name,
      callback: function ($$v) {
        _vm.$set(_vm.resourceAdd, "name", $$v);
      },
      expression: "resourceAdd.name"
    }
  }), _c('BaseTextarea', {
    attrs: {
      "placeholder": _vm.$t('Description')
    },
    model: {
      value: _vm.resourceAdd.description,
      callback: function ($$v) {
        _vm.$set(_vm.resourceAdd, "description", $$v);
      },
      expression: "resourceAdd.description"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "items": _vm.visibilityValues,
      "placeholder": _vm.$t('VisibleTo'),
      "dropdown-allow-null": false,
      "show-dropdown-arrow": true
    },
    model: {
      value: _vm.resourceAdd.visibleTo,
      callback: function ($$v) {
        _vm.$set(_vm.resourceAdd, "visibleTo", $$v);
      },
      expression: "resourceAdd.visibleTo"
    }
  }), _vm.resourceAdd.visibleTo === 'GROUP' ? _c('DropdownMulti', {
    attrs: {
      "items": _vm.groups,
      "selected-items": _vm.resourceAdd.groupIds,
      "height": '52px',
      "display-key": 'name',
      "value-key": 'value',
      "field-type": _vm.$t('Group'),
      "required": true,
      "enable-search": true,
      "error": _vm.errors && _vm.errors.groups === 'FieldRequired' && _vm.resourceAdd.groupIds.length < 1
    },
    on: {
      "input": _vm.updateResourceAddSelectedItems,
      "update:validity": _vm.handleValidity
    }
  }) : _vm._e(), _c('div', {
    staticClass: "add-container-buttons"
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "label": _vm.$t('Cancel')
    },
    on: {
      "click": _vm.closeAddSharedResource
    }
  }), _c('BaseButton', {
    attrs: {
      "size": "large",
      "icon-left": 'checkmark_white_16_16.svg',
      "primary": "true",
      "label": _vm.$t('Submit')
    },
    on: {
      "click": _vm.addSharedResource
    }
  })], 1)], 1) : _vm._e()], 2) : _vm._e(), _vm.sharedResources.length === 0 && !_vm.resourceAdd.open ? _c('p', {
    staticClass: "no-content"
  }, [_vm._v(_vm._s(_vm.$t("NoActiveSharedResources")))]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }