<template>
  <div class="table-header" name="table header">
    <div v-for="column in columns" :key="column.translation + column.sort">
      <div @click="sortByColumn(column, column.sort === 1 ? -1 : 0)" style="padding: 12px 16px; display: flex; height: 100%; width: 100%;" :style="column.style" v-if="column.sort === 1 || column.sort === -1">
        <p  class="content-small-bold">{{$t(column.translation)}}</p>
        <img height="16" width="16" :src="require(`../assets/images/${column.sort === 1 ? 'direction=up.svg' : 'direction=down.svg'}`)" alt="d"/>
      </div>
      <p v-else @click="sortByColumn(column, 1)" style="padding: 12px 16px;" :style="column.style" class="content-small">{{$t(column.translation)}}<p/>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableHeader",
  props: ["columns"],
  methods: {
    sortByColumn(column, order) {
      for (const loopColumn of this.columns) {
        if (loopColumn.field !== column.field) {
          this.$set(loopColumn, 'sort', null);
        }
      }
      column.order = order;
      this.$set(column, 'sort', order);
      this.$emit('reload', { ...column, sort: order });
      this.$forceUpdate();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/global_variables.scss";

.table-header {
  display: flex;
  width: 100%;
  background-color: white;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid $grey500;
}

.table-header div {
  cursor: pointer;
}


</style>