var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_vm.activeView === 'Users' ? _c('UsersContent', {
    attrs: {
      "search": _vm.search,
      "updated": _vm.userUpdated,
      "active-users-only": _vm.activeUsersToggle
    },
    on: {
      "headerData": function (headerData) {
        return _vm.$emit('headerData', headerData);
      }
    }
  }) : _vm._e(), _vm.activeView === 'Settings' ? _c('AdminSettingsContent') : _vm._e(), _vm.activeView === 'UserGroups' ? _c('UserGroupsContent') : _vm._e(), _vm.addUserPanelOpen ? _c('AddUserSidepanel', {
    attrs: {
      "user-settings": false,
      "close-overlay-click": false
    },
    on: {
      "userAdded": _vm.updateUserList,
      "closed": _vm.closePanel
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }