<template>
  <div class="custom-container">
    <div class="custom-table">
      <div class="custom-table-header">
        <div
          v-for="(column, colIndex) in columns"
          :key="'table-column-' + colIndex"
          :style="{
            ...column.style,
            ...(sortField === column.field &&
            (sortOrder === 1 || sortOrder === -1)
              ? { fontWeight: '700' }
              : ''),
          }"
          @click="sortBy(column.field)"
          class="label12"
        >
          <p>{{ $t(`${column.title}`) }}</p>
          <span
            style="height: 16px; width: 16px"
            v-if="sortField === column.field"
          >
            <img
              width="16"
              height="16"
              v-if="sortOrder === 1"
              :src="require('@/assets/images/direction=up.svg')"
              alt=""
            />
            <img
              width="16"
              height="16"
              v-else-if="sortOrder === -1"
              :src="require('@/assets/images/direction=down.svg')"
              alt=""
            />
          </span>
        </div>
      </div>
      <draggable
        v-if="draggable"
        :forceFallback="true"
        :list="sortedRows"
        :animation="200"
        class="custom-table-body"
        @start="(e) => dragStart(e)"
        @end="(e) => dragEnd(e)"
        tag="div"
        handle=".handle"
      >
        <div
          v-for="([, rowValue], rowIndex) in sortedRows"
          :key="'table-row-' + rowIndex"
          class="custom-table-row"
        >
          <div
            v-for="(column, colIndex) in columns"
            :key="'table-cell-' + rowIndex + '-' + colIndex"
            :style="{ ...column.style }"
            :class="{ handle: !column.type }"
            class="table-cell"
            @click="editField(column, rowIndex, colIndex)"
          >
            <div
              class="table-edit-field"
              v-if="
                column.type &&
                (editingField.colIndex !== colIndex ||
                  editingField.rowIndex !== rowIndex)
              "
            >
              <button
                @focus="editField(column, rowIndex, colIndex)"
                :ref="'table-button-' + rowIndex + '-' + colIndex"
                class="content-normal"
              >
                {{ rowValue[column.field] || column.emptyValue }}
              </button>
            </div>
            <textarea
              class="table-textarea"
              :ref="'table-edit-' + rowIndex + '-' + colIndex"
              v-model="rowValue[column.field]"
              @blur="closeEditField(rowIndex)"
              v-else-if="
                editingField.colIndex === colIndex &&
                editingField.rowIndex === rowIndex
              "
              @keydown.esc="(e) => blurEditField(e, rowIndex, colIndex, false)"
              @keydown.enter="(e) => blurEditField(e, rowIndex, colIndex)"
            />
            <p v-else class="content-normal">
              {{ rowValue[column.field] }}
            </p>
            <!--   <p v-else class="content-normal">
              {{ rowValue[column.field] ? '*' : '' }}
            </p> -->
          </div>
        </div>
      </draggable>
      <div v-else class="custom-table-body">
        <div
          v-for="(row, rowIndex) in paginatedRows"
          :key="'table-row-' + rowIndex"
          class="custom-table-row"
        >
          <div
            v-for="(column, colIndex) in columns"
            :key="'table-cell-' + rowIndex + '-' + colIndex"
            :style="{ ...column.style }"
            class="custom-table-cell"
          >
            {{ row[column.field] }}
          </div>
        </div>
      </div>
    </div>
    <div class="add-user-buttons">
      <button @click="$emit('closed')" class="cancel">
        {{ $t("Cancel") }}
      </button>
      <div class="add-button" @click="$emit('updated')">
        <img
          style="margin-right: 9px"
          alt=""
          src="../../assets/images/checkmarkCircled.svg"
        />
        <p>{{ $t("Submit") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable-rp";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CustomTable",
  components: {
    draggable,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortField: "",
      sortOrder: 0,
      sortedRows: this.rows,
      editingField: {},
    };
  },
  watch: {
    rows: {
      handler() {
      },
      deep: true,
    },
  },
  created() {
    this.setupOrderingAndFiltering();
  },
  computed: {
    ...mapGetters(["users"]),
  },
  methods: {
    setupOrderingAndFiltering() {
      this.sortedRows = this.sortedRows.filter(([a,b]) => {
        return this.users.some((user) => {
          if (user._id === a && user.userStatus === 'Inactive') {
            b.ordering = null
          }
          return user._id === a && user.userStatus !== "Inactive";
        });
      });
      this.sortedRows = this.sortedRows.sort(([, a], [, b]) => {
        return a.ordering - b.ordering;
      });
    },
    ...mapActions(["getUsers"]),
    sortBy(field) {
      if (this.sortField === field) {
        if (this.sortOrder === 1) {
          this.sortOrder = -1;
        } else {
          this.sortOrder = 0;
          this.sortField = "";
        }
      } else {
        this.sortField = field;
        this.sortOrder = 1;
      }
      if (this.sortOrder === 0) {
        this.setupOrderingAndFiltering();
      } else {
        this.sortedRows = this.sortedRows.sort((a, b) => {
          const aValue = a[1][field];
          const bValue = b[1][field];
          if (aValue === bValue) return 0;
          const order = aValue > bValue ? 1 : -1;
          return this.sortOrder === 1 ? order : -order;
        });
      }
    },
    dragStart(e) {
      this.editingField = {};
      e.item.classList.add("grabbing");
      e.target.classList.add("grabbing");
    },
    dragEnd(e) {
      e.item.classList.remove("grabbing");
      e.target.classList.remove("grabbing");
      this.orderList();
    },
    editField(column, rowIndex, colIndex) {
      if (!column.type) return;
      this.editingField = {
        rowIndex: rowIndex,
        colIndex: colIndex,
      };
      this.$nextTick(() => {
        const ref = this.$refs["table-edit-" + rowIndex + "-" + colIndex];
        if (ref && ref[0]) {
          ref[0].focus();
        }
      });
    },
    closeEditField(rowIndex) {
      this.editingField = {};
      const removedItem = this.sortedRows.splice(rowIndex, 1)[0];
      removedItem[1].ordering = parseInt(removedItem[1].ordering);
      if (this.sortOrder === -1) {
        this.sortedRows.splice(
          this.sortedRows.length - (removedItem[1].ordering - 1),
          0,
          removedItem
        );
      } else {
        this.sortedRows.splice(removedItem[1].ordering - 1, 0, removedItem);
      }
      this.orderList();
    },
    orderList(setOrderNumbers = true) {
      if (this.sortField === "ordering" || !this.sortField) {
        // Set ordering numbers
        if (setOrderNumbers) {
          if (this.sortOrder === -1) {
            let counter = 0;
            for (let i = this.sortedRows.length - 1; i > -1; i--) {
              this.sortedRows[counter][1].ordering = i + 1;
              counter++;
            }
          } else {
            for (let i = 0; i < this.sortedRows.length; i++) {
              this.sortedRows[i][1].ordering = i + 1;
            }
          }
        }
        this.$nextTick(() => {
          this.sortedRows.sort(this.compare);
        });
      }
    },
    compare(a, b) {
      const return1 = this.sortOrder === -1 ? 1 : -1;
      const return2 = this.sortOrder === -1 ? -1 : 1;

      const orderingA = a.ordering || 0;
      const orderingB = b.ordering || 0;

      if (orderingA < orderingB) {
        return return1;
      }
      if (orderingA > orderingB) {
        return return2;
      }
      return 0;
    },
    blurEditField(e, rowIndex, colIndex, focusNext = true) {
      // This method is called when user presses enter
      // Don't allow new line
      e.preventDefault();
      // Close current edit
      const ref = this.$refs["table-edit-" + rowIndex + "-" + colIndex];
      if (ref && ref[0]) {
        ref[0].blur();
      }
      if (focusNext) {
        // Focus on next edit
        this.$nextTick(() => {
          const row = parseInt(rowIndex) + 1;
          const buttonRef = this.$refs["table-button-" + row + "-" + colIndex];
          if (buttonRef && buttonRef[0]) {
            buttonRef[0].focus();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.custom-table {
  width: 100%;
  height: 100%;
  background-color: $grey500;
  border-radius: 8px;
}

.custom-table-header {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 2px;
  height: 40px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}

.custom-table-row {
  display: flex;
  flex-wrap: nowrap;
  height: 44px;
  background-color: #fff;
  cursor: grab;
}

.custom-table-row:hover {
  background-color: $light-grey;
}

.custom-table-row:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.custom-table-header-cell,
.custom-table-cell {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.custom-table-header-cell {
  font-weight: bold;
  cursor: pointer;
}

.custom-table-body {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.grabbing * {
  cursor: grabbing !important;
}

.table-textarea {
  outline: none;
  resize: none;
  height: 22px;
  width: 100%;
}

.table-textarea:focus {
  padding: 0 4px;
  border-bottom: 2px solid #16afb9;
  background-color: $light-grey;
}

.table-edit-field {
  display: flex;
  flex-wrap: nowrap;
}

.table-edit-field button {
  padding: 0 4px;
}

.table-cell:hover .table-edit-field button {
  border-radius: 4px;
  background-color: $grey500;
}
.add-user-buttons {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.add-button {
  cursor: pointer !important;
  padding-left: 12px;
  padding-right: 12px;
}

.add-button p {
  color: white;
}
.cancel {
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 8px;
  background: white;
}

</style>
