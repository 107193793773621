var render = function render(){
  var _vm$company$access$fe, _vm$company$access$fe2, _vm$company$access$fe3, _vm$company$access$fe4, _vm$company$access$fe5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [!_vm.loaded ? _c('div', [_c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "24px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  })]) : _vm._e(), _vm.loaded ? _c('div', {
    attrs: {
      "id": "settings-content"
    }
  }, [_c('BaseDetailviewContainer', {
    attrs: {
      "width": 680,
      "padding": 24
    }
  }, [_c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('UserRightsManagement'),
      "button-name": _vm.$t('Edit'),
      "padding-top": '0px',
      "button-opens-accordion": true,
      "button-icon": 'edit_16_16.svg',
      "identifier": 'userRights',
      "show-bot-divider": true,
      "parent-name": 'settings',
      "toggle-button": true,
      "toggle-text": _vm.$t('UserCanLimitVisibility'),
      "toggle-status": _vm.visibilityLimitable
    },
    on: {
      "buttonPressed": _vm.editVisibility,
      "toggleAction": _vm.toggleUserVisibility
    }
  }, [_c('div', {
    key: _vm.updateVisibilityValue,
    staticClass: "setting-row",
    staticStyle: {
      "max-width": "200px"
    }
  }, [_c('BaseDropdown', {
    staticStyle: {
      "flex": "4"
    },
    attrs: {
      "id": 'sidepanel-contact-ownership',
      "items": _vm.visibilityValues,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('DefaultVisibilityValue'),
      "show-dropdown-arrow": true,
      "disabled": !_vm.visibilityEdit
    },
    model: {
      value: _vm.visibilityValue,
      callback: function ($$v) {
        _vm.visibilityValue = $$v;
      },
      expression: "visibilityValue"
    }
  })], 1), _c('BaseToggle', {
    staticStyle: {
      "margin-left": "8px",
      "margin-top": "16px"
    },
    attrs: {
      "value": _vm.user.companyPolicies.filters.projects.showAll,
      "label": _vm.$t('ShowAllProjectsToAllUsers')
    },
    on: {
      "change": function (val) {
        return _vm.changePolicyFilter('projects', val);
      }
    }
  }), _c('BaseToggle', {
    staticStyle: {
      "margin-left": "8px",
      "margin-top": "16px"
    },
    attrs: {
      "value": _vm.user.companyPolicies.filters.transactions.showAll,
      "label": _vm.$t('ShowAllTransactionsToAllUsers')
    },
    on: {
      "change": function (val) {
        return _vm.changePolicyFilter('transactions', val);
      }
    }
  }), _c('div', {
    staticClass: "submit-cancel-wrapper"
  }, [_vm.visibilityEdit ? _c('CancelButton', {
    staticClass: "top-24",
    on: {
      "clicked": _vm.editVisibility
    }
  }) : _vm._e(), _vm.visibilityEdit ? _c('SubmitButton', {
    staticClass: "top-24",
    on: {
      "clicked": _vm.saveVisibility
    }
  }) : _vm._e()], 1)], 1), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('ListingsPublishing'),
      "separator-line": true,
      "identifier": 'listingChannels',
      "show-bot-divider": true,
      "parent-name": 'settings'
    }
  }, [_c('div', {
    staticClass: "setting-section"
  }, [(_vm$company$access$fe = _vm.company.access.features) !== null && _vm$company$access$fe !== void 0 && (_vm$company$access$fe2 = _vm$company$access$fe.modules) !== null && _vm$company$access$fe2 !== void 0 && (_vm$company$access$fe3 = _vm$company$access$fe2.listings) !== null && _vm$company$access$fe3 !== void 0 && (_vm$company$access$fe4 = _vm$company$access$fe3.features) !== null && _vm$company$access$fe4 !== void 0 && (_vm$company$access$fe5 = _vm$company$access$fe4.aml) !== null && _vm$company$access$fe5 !== void 0 && _vm$company$access$fe5.enabled && _vm.amlDropdownLoaded ? _c('div', [_c('div', {
    staticClass: "content-bold"
  }, [_vm._v(_vm._s(_vm.$t('AMLSettings')))]), _c('div', {
    staticStyle: {
      "width": "200px",
      "padding": "12px 0"
    }
  }, [_c('DropdownMulti', {
    attrs: {
      "items": _vm.amlFeatureDropdownItems,
      "selected-items": _vm.selectedAmlDropdownItems,
      "translate": true,
      "display-key": 'name',
      "value-key": 'value',
      "width": '200px',
      "default-value": _vm.$t('None')
    },
    on: {
      "input": _vm.amlValueChanged
    },
    model: {
      value: _vm.selectedAmlDropdownItems,
      callback: function ($$v) {
        _vm.selectedAmlDropdownItems = $$v;
      },
      expression: "selectedAmlDropdownItems"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "xml-data",
    staticStyle: {
      "margin-top": "0 !important"
    }
  }, [_c('div', {
    staticClass: "content-bold",
    staticStyle: {
      "padding": "12px 0"
    }
  }, [_vm._v(_vm._s(_vm.$t('Outputs')))]), _c('p', {
    staticClass: "content-small content-semibold"
  }, [_vm._v(" " + _vm._s(_vm.user.isItaly ? "Immobiliare" : "KV24") + " ")]), _c('a', {
    staticClass: "link",
    attrs: {
      "href": `https://app.runproperty.com/xmls/kv24/${_vm.user.companyId}.xml`
    }
  }, [_vm._v(" " + _vm._s(`https://app.runproperty.com/xmls/kv24/${_vm.user.companyId}.xml`) + " ")])]), _c('div', {
    staticClass: "xml-data"
  }, [_c('p', {
    staticClass: "content-small content-semibold"
  }, [_vm._v(" " + _vm._s(_vm.user.isItaly ? "Idealista" : "CITY24") + " ")]), _vm.user.companyId === '5p1EOo4yqjeiYCN3R16w' ? _c('a', {
    staticClass: "link",
    attrs: {
      "href": `https://app.runproperty.com/xmls/city24/0XMZ7rJji5wTQuKp_mw7.xml`
    }
  }, [_vm._v(" " + _vm._s(`https://app.runproperty.com/xmls/city24/0XMZ7rJji5wTQuKp_mw7.xml`) + " ")]) : _c('a', {
    staticClass: "link",
    attrs: {
      "href": `https://app.runproperty.com/xmls/city24/${_vm.user.companyId}.xml`
    }
  }, [_vm._v(" " + _vm._s(`https://app.runproperty.com/xmls/city24/${_vm.user.companyId}.xml`) + " ")])]), _c('div', {
    staticClass: "xml-data"
  }, [_c('p', {
    staticClass: "content-small content-semibold"
  }, [_vm._v(" " + _vm._s(_vm.user.isItaly ? "Casa" : "KV.ee") + " ")]), _c('a', {
    staticClass: "link",
    attrs: {
      "href": `https://app.runproperty.com/xmls/kv/${_vm.user.companyId}`
    }
  }, [_vm._v(" " + _vm._s(`https://app.runproperty.com/xmls/kv/${_vm.user.companyId}`) + " ")])]), _vm.company.outputLinks && _vm.company.outputLinks.www ? _c('div', {
    staticClass: "xml-data",
    staticStyle: {
      "margin-top": "12px"
    }
  }, [_c('p', {
    staticClass: "content-small content-semibold"
  }, [_vm._v("WWW")]), _c('a', {
    staticClass: "link",
    attrs: {
      "href": _vm.getCompanyWwwLink(),
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v(" " + _vm._s(_vm.getCompanyWwwLink()) + " ")])]) : _vm._e(), _vm.showOkidoki() ? _c('div', {
    staticClass: "xml-data"
  }, [_c('p', {
    staticClass: "content-small content-semibold"
  }, [_vm._v(" Okidoki ")]), _c('a', {
    staticClass: "link",
    attrs: {
      "href": `https://app.runproperty.com/xmls/okidoki/${_vm.user.companyId}.xml`
    }
  }, [_vm._v(" " + _vm._s(`https://app.runproperty.com/xmls/okidoki/${_vm.user.companyId}.xml`) + " ")])]) : _vm._e(), _vm.showSS() ? _c('div', {
    staticClass: "xml-data"
  }, [_c('p', {
    staticClass: "content-small content-semibold"
  }, [_vm._v(" SS.com ")]), _c('a', {
    staticClass: "link",
    attrs: {
      "href": `https://app.runproperty.com/xmls/ss/${_vm.user.companyId}.xml`
    }
  }, [_vm._v(" " + _vm._s(`https://app.runproperty.com/xmls/ss/${_vm.user.companyId}.xml`) + " ")])]) : _vm._e(), _vm.showChristies() ? _c('div', {
    staticClass: "xml-data"
  }, [_c('p', {
    staticClass: "content-small content-semibold"
  }, [_vm._v(" Christies ")]), _c('a', {
    staticClass: "link",
    attrs: {
      "href": `https://app.runproperty.com/xmls/christies/${_vm.user.companyId}.xml`
    }
  }, [_vm._v(" " + _vm._s(`https://app.runproperty.com/xmls/christies/${_vm.user.companyId}.xml`) + " ")])]) : _vm._e()])]), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('ListingsDaysActive'),
      "button-name": _vm.$t('Edit'),
      "show-bot-divider": true,
      "button-opens-accordion": true,
      "button-icon": 'edit_16_16.svg',
      "identifier": 'listingsDaysActive',
      "parent-name": 'settings',
      "toggle-button": true,
      "toggle-text": _vm.activeDaysStatusText,
      "toggle-status": _vm.activeDaysEnabled
    },
    on: {
      "buttonPressed": _vm.editActiveDays,
      "toggleAction": _vm.toggleActiveDaysStatus
    }
  }, [_c('div', {
    staticClass: "setting-section"
  }, [!_vm.activeDaysEdit ? _c('p', {
    staticClass: "content",
    staticStyle: {
      "color": "black !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.activeDays) + " ")]) : _vm._e(), _vm.activeDaysEdit ? _c('BaseInput', {
    staticStyle: {
      "width": "88px"
    },
    attrs: {
      "placeholder": _vm.$t('Days'),
      "type": "number"
    },
    model: {
      value: _vm.activeDays,
      callback: function ($$v) {
        _vm.activeDays = $$v;
      },
      expression: "activeDays"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "submit-cancel-wrapper"
  }, [_vm.activeDaysEdit ? _c('CancelButton', {
    staticClass: "top-24",
    on: {
      "clicked": _vm.editActiveDays
    }
  }) : _vm._e(), _vm.activeDaysEdit ? _c('SubmitButton', {
    staticClass: "top-24",
    on: {
      "clicked": _vm.saveActiveDays
    }
  }) : _vm._e()], 1)], 1)]), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('ListingsCorporateImage'),
      "button-name": _vm.$t('Edit'),
      "show-bot-divider": true,
      "button-opens-accordion": true,
      "button-icon": 'edit_16_16.svg',
      "identifier": 'ListingsCorporateImage',
      "parent-name": 'settings',
      "toggle-button": true,
      "toggle-text": _vm.listingCorporateImageStatus ? _vm.$t('Active') : _vm.$t('Inactive'),
      "toggle-status": _vm.listingCorporateImageStatus
    },
    on: {
      "buttonPressed": _vm.editListingsCorporateImage,
      "toggleAction": _vm.toggleListingCorporateImageStatus
    }
  }, [_c('div', {
    staticClass: "setting-section"
  }, [_c('CorporateImageSection', {
    attrs: {
      "edit": _vm.listingsCorporateImageEdit,
      "company": _vm.company
    },
    on: {
      "closeEdit": function ($event) {
        _vm.listingsCorporateImageEdit = false;
      },
      "updateCompanyData": _vm.getCompanyInfo
    }
  })], 1)]), _c('BaseAccordion', {
    attrs: {
      "button-name": _vm.$t('Add'),
      "title": _vm.$t('SharedResources'),
      "identifier": 'SharedResources',
      "show-bot-divider": true,
      "button-opens-accordion": true,
      "button-icon": 'plus.svg',
      "parent-name": 'settings'
    },
    on: {
      "buttonPressed": function ($event) {
        _vm.openAddSharedResource++;
      }
    }
  }, [_c('div', {
    staticClass: "setting-section"
  }, [_c('div', [_c('SharedResources', {
    attrs: {
      "add-resource": _vm.openAddSharedResource
    }
  })], 1)])]), _c('BaseAccordion', {
    attrs: {
      "button-opens-accordion": true,
      "title": _vm.$t('FooterText'),
      "button-name": _vm.$t('Edit'),
      "identifier": 'footerText',
      "button-icon": 'edit_16_16.svg',
      "parent-name": 'settings',
      "toggle-button": true,
      "show-bot-divider": true,
      "toggle-text": _vm.footerStatusText,
      "toggle-status": _vm.footerEnabled
    },
    on: {
      "buttonPressed": _vm.editFooter,
      "toggleAction": _vm.toggleFooterStatus
    }
  }, [_c('div', {
    staticClass: "setting-section"
  }, [_c('div', {
    staticClass: "language-selection"
  }, [_vm.user.access.company._id === 'xmwei_Rt9ryfpkxL4BL8' ? _c('div', {
    class: {
      'active-view': _vm.activeView === 'lv',
      '': _vm.activeView !== 'lv'
    },
    attrs: {
      "id": "latvianPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'lv';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("LatvianLanguage")) + " ")])]) : _vm._e(), _c('div', {
    class: {
      'active-view': _vm.activeView === 'et',
      '': _vm.activeView !== 'et'
    },
    attrs: {
      "id": "estonianPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'et';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("EstonianLanguage")) + " ")])]), _c('div', {
    class: {
      'active-view': _vm.activeView === 'eng',
      '': _vm.activeView !== 'eng'
    },
    attrs: {
      "id": "englishPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'eng';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("EnglishLanguage")) + " ")])]), _c('div', {
    class: {
      'active-view': _vm.activeView === 'ru',
      '': _vm.activeView !== 'ru'
    },
    attrs: {
      "id": "russianPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'ru';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("RussianLanguage")) + " ")])]), _c('div', {
    class: {
      'active-view': _vm.activeView === 'fi',
      '': _vm.activeView !== 'fi'
    },
    attrs: {
      "id": "finnishPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'fi';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("FinnishLanguage")) + " ")])])]), (!_vm.footerText || !_vm.footerText[_vm.activeView]) && !_vm.footerEdit ? _c('p', {
    staticClass: "grey900"
  }, [_vm._v(" " + _vm._s(_vm.$t("None")) + " ")]) : _vm._e(), !_vm.footerEdit && _vm.footerText && _vm.footerText[_vm.activeView] ? _c('pre', {
    staticClass: "content-normal",
    staticStyle: {
      "white-space": "pre-wrap"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.footerText[_vm.activeView])
    }
  }) : _vm._e(), _vm.footerEdit && _vm.footerText ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.footerText[_vm.activeView],
      expression: "footerText[activeView]"
    }],
    attrs: {
      "id": "textarea",
      "name": "textarea",
      "rows": "10"
    },
    domProps: {
      "value": _vm.footerText[_vm.activeView]
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.footerText, _vm.activeView, $event.target.value);
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "submit-cancel-wrapper"
  }, [_vm.footerEdit ? _c('CancelButton', {
    staticClass: "top-24",
    on: {
      "clicked": _vm.editFooter
    }
  }) : _vm._e(), _vm.footerEdit ? _c('SubmitButton', {
    staticClass: "top-24",
    on: {
      "clicked": _vm.saveFooter
    }
  }) : _vm._e()], 1)])]), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('CompanyLogo'),
      "parent-name": 'settings',
      "identifier": 'companyLogo'
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "16px",
      "justify-content": "space-between"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "height": "fit-content",
      "gap": "16px"
    }
  }, [_c('input', {
    ref: "fileInput",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "type": "file",
      "id": "file-upload-input",
      "hidden": ""
    },
    on: {
      "change": _vm.uploadCompanyLogo
    }
  }), _c('div', {
    staticStyle: {
      "padding": "4px 8px",
      "cursor": "pointer",
      "border": "1px dashed #c3c4c6",
      "height": "24px",
      "gap": "6px",
      "border-radius": "8px",
      "display": "flex",
      "align-items": "center"
    },
    on: {
      "click": function () {
        return _vm.$refs.fileInput.click();
      }
    }
  }, [_c('img', {
    attrs: {
      "height": "16",
      "src": require('@/assets/images/image.svg'),
      "alt": ""
    }
  }), _c('label', {
    staticStyle: {
      "width": "100%",
      "cursor": "pointer",
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(_vm.$t("Upload")))])]), _vm.selectedFile ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.selectedFile.name) + " ")]) : _vm._e(), _vm.errorMessage ? _c('p', {
    staticClass: "content-normal",
    staticStyle: {
      "color": "#ff1e24"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.errorMessage)) + " 260x56 ")]) : _vm._e()]), _vm.company && _vm.company.companyLogo && _vm.company.companyLogo.name ? _c('img', {
    key: 'company-logo' + _vm.logoUpdater,
    staticStyle: {
      "width": "260px",
      "height": "56px",
      "background": "#e6e8ec",
      "object-fit": "contain"
    },
    attrs: {
      "src": _vm.company.companyLogo.name,
      "alt": ""
    }
  }) : _vm._e()]), _c('div', {
    staticStyle: {
      "margin-top": "24px",
      "display": "flex",
      "flex-direction": "column",
      "gap": "8px"
    }
  }, [_c('p', {
    staticClass: "content-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("PrioritizePrimaryListingImageFrom")) + ": ")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "cursor": "pointer",
      "gap": "8px",
      "height": "24px",
      "width": "fit-content",
      "align-items": "center"
    },
    on: {
      "click": function () {
        return _vm.setCompanyListingPrioritization('top');
      }
    }
  }, [_c('div', {
    staticStyle: {
      "width": "16px",
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm.company && _vm.company.companyLogo && _vm.company.companyLogo.prioritization === 'top' ? _c('img', {
    attrs: {
      "src": require('@/assets/images/checkmark.svg'),
      "alt": ""
    }
  }) : _vm._e()]), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.$t("top")))])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "cursor": "pointer",
      "gap": "8px",
      "height": "24px",
      "width": "fit-content",
      "align-items": "center"
    },
    on: {
      "click": function () {
        return _vm.setCompanyListingPrioritization('center');
      }
    }
  }, [_c('div', {
    staticStyle: {
      "width": "16px",
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm.company && _vm.company.companyLogo && _vm.company.companyLogo.prioritization === 'center' || !_vm.company || !_vm.company.companyLogo || !_vm.company.companyLogo.prioritization ? _c('img', {
    attrs: {
      "src": require('@/assets/images/checkmark.svg'),
      "alt": ""
    }
  }) : _vm._e()]), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.$t("center")))])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "cursor": "pointer",
      "gap": "8px",
      "height": "24px",
      "width": "fit-content",
      "align-items": "center"
    },
    on: {
      "click": function () {
        return _vm.setCompanyListingPrioritization('bottom');
      }
    }
  }, [_c('div', {
    staticStyle: {
      "width": "16px",
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm.company && _vm.company.companyLogo && _vm.company.companyLogo.prioritization === 'bottom' ? _c('img', {
    attrs: {
      "src": require('@/assets/images/checkmark.svg'),
      "alt": ""
    }
  }) : _vm._e()]), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.$t("bottom")))])])])])], 1)], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }