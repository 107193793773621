var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-row-wrapper",
    on: {
      "click": function ($event) {
        return _vm.$emit('click', _vm.rowData);
      }
    }
  }, _vm._l(_vm.columns, function (column) {
    var _column$table, _column$table$editing, _column$table2, _vm$rowData$column$fi;
    return _c('div', {
      key: column.field,
      style: column.style
    }, [((_column$table = column.table) === null || _column$table === void 0 ? void 0 : (_column$table$editing = _column$table.editing) === null || _column$table$editing === void 0 ? void 0 : _column$table$editing._id) === _vm.rowData._id ? _c('TableTextInput', {
      ref: `tableTextInput-${_vm.rowData._id}-${column.field}`,
      refInFor: true,
      on: {
        "blur": function ($event) {
          return _vm.exitRowEdit(column);
        }
      },
      model: {
        value: _vm.rowData[column.field].value[column.table.editField],
        callback: function ($$v) {
          _vm.$set(_vm.rowData[column.field].value, column.table.editField, $$v);
        },
        expression: "rowData[column.field].value[column.table.editField]"
      }
    }) : _c('p', {
      staticClass: "content-normal",
      class: {
        'table-row-editable': ((_column$table2 = column.table) === null || _column$table2 === void 0 ? void 0 : _column$table2.editable) && ((_vm$rowData$column$fi = _vm.rowData[column.field]) === null || _vm$rowData$column$fi === void 0 ? void 0 : _vm$rowData$column$fi.value) && _vm.accessOverUser
      },
      domProps: {
        "innerHTML": _vm._s(_vm.formatRowData(column))
      },
      on: {
        "click": function (e) {
          return _vm.editRow(e, column);
        }
      }
    })], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }