<template>
  <div id="user-groups-content">
    <h5>{{ $t("UserGroups") }}</h5>

    <CompanyGroups />
    <div
      style="width: 100%; margin-right: 20px; margin-top: 12px"
      v-if="addGroupOpen && Object.keys(groups).length - 1"
    >
      <v-text-field
        style="width: 100%; max-width: 616px"
        v-if="addGroupOpen"
        @focusout="addNewGroup"
        @keydown.enter="$event.target.blur()"
        ref="groupAdd"
        hide-details
        dense
        outlined
        v-model="addGroupName"
      />
    </div>
    <div
      v-if="Object.keys(groups).length > 0 && user.isAdmin"
      class="button-container"
    >
      <div @click="addGroupButton" class="link-small" style="cursor: pointer">
        + {{ $t("AddAnother") }}
      </div>
    </div>
    <div
      v-if="Object.keys(groups).length < 1 && user.isAdmin"
      class="button-container"
      style="margin-top: 0px"
    >
      <div @click="addGroupButton" class="link-small" style="cursor: pointer">
        + {{ $t("AddFirst") }}
      </div>
    </div>
  </div>

  <!--  <div
      v-for="(item, id, index) in companyGroups"
      class="group-card"
      :key="id"
      v-bind:class="{
        'first-card': index === 0 && Object.keys(groups).length > 1,
        'only-card': Object.keys(groups).length === 1,
        'last-card': index === Object.keys(groups).length - 1 && Object.keys(groups).length > 1,
        'middle-card': index > 0 && index < Object.keys(groups).length - 1,
      }"
    >
      <div
        style="width: 100%; margin-right: 20px"
        v-if="addGroupOpen && index === Object.keys(groups).length - 1"
      >
        <v-text-field
          style="width: 100%; max-width: 560px"
          v-if="addGroupOpen"
          @focusout="addNewGroup"
          @keydown.enter="$event.target.blur()"
          ref="groupAdd"
          hide-details
          dense
          outlined
          v-model="addGroupName"
        />
      </div>
      <div
        style="width: 100%; margin-right: 20px"
        v-if="id === selectedGroupId"
      >
        <v-text-field
          style="width: 100%; max-width: 560px"
          v-if="selectedGroupId"
          @focusout="updateGroup"
          @keydown.enter="$event.target.blur()"
          ref="groupUpdate"
          hide-details
          dense
          outlined
          v-model="item.name"
        />
      </div>
      <div v-else>
        <p>{{ item.name }}</p>
        <p class="gray-text" style="font-size: 14px" v-if="item.isDefault">
          {{ $t("Default") }}
        </p>
      </div>
      <IconDropdown
        :items="groupChoices(item)"
        :display-key="'name'"
        :value-key="'value'"
        :icon="'dot_menu_gray.svg'"
        :object="{_id: id, ...item}"
        @itemSelected="selectActivity"
      ></IconDropdown>
    </div>
    <div v-if="Object.keys(groups).length > 0 && user.isAdmin" class="button-container">
      <div @click="addGroupButton" class="link-small" style="cursor: pointer">
        + {{ $t("AddAnother") }}
      </div>
    </div>
    <div
      v-if="Object.keys(groups).length < 1 && user.isAdmin"
      class="button-container"
      style="margin-top: 0px"
    >
      <div @click="addGroupButton" class="link-small" style="cursor: pointer">
        + {{ $t("AddFirst") }}
      </div>
    </div>
    <DeleteModal
      :custom-message="deleteMessage"
      :removing="false"
      @canceled="isDeleteModalOpen = false"
      @approved="deleteGroup"
      v-if="isDeleteModalOpen"
    />
  </div> -->
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
//import IconDropdown from "@/components/common/IconDropdown";
//import DeleteModal from "@/components/common/DeleteModal";
import nanoid from "nanoid";
import CompanyGroups from "@/components/Admin/CompanyGroups";

export default {
  name: "UserGroupsContent",
  components: { CompanyGroups },
  data() {
    return {
      addGroupOpen: false,
      addGroupName: "",
      selectedGroupId: null,
      selectedGroupName: null,
      isDeleteModalOpen: false,
      groupToDelete: null,
      deleteMessage: "",
    };
  },
  async created() {
    await this.getGroups();
  },
  computed: {
    ...mapGetters(["user", "groups"]),
    companyGroups() {
      if (this.user.isAdmin) {
        return this.groups;
      } else {
        const returnGroups = {};
        for (const groupId in this.user.access.groups) {
          if (this.user.access.groups[groupId].isAdmin) {
            returnGroups[groupId] = this.user.access.groups[groupId];
          }
        }
        return returnGroups;
      }
    },
  },
  methods: {
    ...mapActions(["getUsers", "getGroups"]),
    groupChoices(group) {
      if (group.isDefault) {
        return [
          {
            name: this.$t("Edit"),
            value: "edit",
            _id: nanoid(8),
          },
        ];
      } else if (this.user.isAdmin) {
        return [
          {
            name: this.$t("Edit"),
            value: "edit",
            _id: nanoid(8),
          },
          {
            name: this.$t("SetDefault"),
            value: "set_default",
            _id: nanoid(8),
          },
          {
            name: this.$t("Delete"),
            value: "delete",
            _id: nanoid(8),
          },
        ];
      } else {
        return [
          {
            name: this.$t("Edit"),
            value: "edit",
            _id: nanoid(8),
          },
        ];
      }
    },
    addGroupButton() {
      if (this.selectedGroupId !== null) {
        return;
      }
      if (this.addGroupOpen) {
        return;
      }
      this.groups[""] = {};
      this.addGroupOpen = true;
      this.$nextTick(() => this.$refs.groupAdd[0].focus());
    },
    async addNewGroup() {
      if (this.addGroupName === "" || /^\s*$/.test(this.addGroupName)) {
        this.addGroupName = "";
        this.groups.pop();
        this.addGroupOpen = false;
      } else {
        this.groups[this.groups.length - 1] = this.addGroupName;
        await axios.post("/api/create-group", {
          companyId: this.user.companyId,
          groupName: this.addGroupName,
        });
        await this.getGroups();
        this.addGroupName = "";
        this.addGroupOpen = false;
      }
    },
    async selectActivity(activity) {
      console.log(activity);
      if (activity.item.value === "edit") {
        this.selectedGroupId = activity.object._id;
        this.selectedGroupName = activity.object.name;
        this.$nextTick(() => this.$refs.groupUpdate[0].focus());
      }
      if (activity.item.value === "delete") {
        if (!this.user.isAdmin) {
          return;
        }
        this.groupToDelete = activity.object._id;
        this.deleteMessage = `${this.$t("DeleteTheGroup")} ${
          activity.object.name
        } ${this.$t("andMove")} ${activity.object.users.length} ${this.$t(
          "usersToDefaultGroup"
        )}?`;
        this.isDeleteModalOpen = true;
      }
      if (activity.item.value === "set_default") {
        await axios.post("/api/group/default", {
          groupId: activity.object._id,
          companyId: this.user.companyId,
        });
      }
      await this.getGroups();
    },
    async deleteGroup() {
      try {
        await axios.post("/api/delete-group", {
          groupId: this.groupToDelete,
          companyId: this.user.companyId,
        });
        this.toastSuccess(this.$t("Deleted"));
        this.isDeleteModalOpen = false;
        this.groupToDelete = null;
        await this.getGroups();
      } catch (e) {
        this.toastError("ErrorDeleting");
      }
    },
    async updateGroup() {
      const newGroupName = this.groups[this.selectedGroupId].name;
      if (newGroupName === this.selectedGroupName) {
        this.selectedGroupId = null;
        this.selectedGroupName = null;
        return;
      }
      if (newGroupName === "" || /^\s*$/.test(newGroupName)) {
        this.groups.filter(
          (group) => group._id === this.selectedGroupId
        )[0].name = this.selectedGroupName;
      } else {
        await axios
          .post("/api/edit-group", {
            groupId: this.selectedGroupId,
            companyId: this.user.companyId,
            newName: newGroupName,
          })
          .then((res) => console.log(res))
          .catch((err) => {
            console.log(err);
          });
      }
      this.selectedGroupId = null;
      this.selectedGroupName = null;
    },
  },
};
</script>

<style scoped>
#user-groups-content {
  display: flex;
  flex-direction: column;
  max-width: 680px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 32px;
}

#user-groups-content h5 {
  margin-bottom: 24px;
}

#user-groups-content .group-card {
  border: 1px solid #e6e8ec;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.only-card {
  border-radius: 8px;
}

.first-card {
  -webkit-border-radius: 8px 8px 0px 0px;
  border-radius: 8px 8px 0px 0px;
}

.middle-card {
  border-top: none !important;
}

.last-card {
  -webkit-border-radius: 0px 0px 8px 8px;
  border-radius: 0px 0px 8px 8px;
  border-top: none !important;
}

.button-container {
  display: flex;
  padding: 12px;
  margin-top: 24px;
}
</style>
