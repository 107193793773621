var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data ? _c('div', {
    key: _vm.updateKey,
    staticClass: "table-wrapper",
    attrs: {
      "name": "wrapper"
    }
  }, [_c('TableHeader', {
    attrs: {
      "columns": _vm.data.columns
    },
    on: {
      "reload": function (column) {
        return _vm.$emit('reload', column);
      }
    }
  }), _vm.data.rows.length > 0 ? _vm._l(_vm.data.rows, function (row) {
    return _c('TableRow', {
      key: row._id,
      attrs: {
        "columns": _vm.data.columns,
        "row-data": row
      },
      on: {
        "click": function (rowData) {
          return _vm.$emit('click', rowData);
        },
        "reload": function ($event) {
          return _vm.$emit('reload');
        }
      }
    });
  }) : _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "height": "64px",
      "padding": "12px 16px",
      "line-height": "40px",
      "background-color": "white",
      "border-radius": "0 0 8px 8px"
    }
  }, [_vm._v(_vm._s(_vm.$t("NoResultsFound")))])], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }