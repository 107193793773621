var render = function render(){
  var _vm$headerData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header",
    staticStyle: {
      "background-color": "white"
    }
  }, [_c('v-row', {
    staticClass: "title-row"
  }, [_c('h3', {
    staticClass: "header-title"
  }, [_vm._v(_vm._s(_vm.$t("Admin")))]), _c('AddNewButton')], 1), _c('v-row', {
    staticStyle: {
      "width": "100%",
      "height": "42px",
      "margin-top": "0",
      "padding-left": "104px"
    }
  }, [_c('div', {
    class: {
      'active-view': _vm.activeView === 'Users'
    },
    attrs: {
      "id": "usersView"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'Users';
      }
    }
  }, [_c('p', {
    staticClass: "content-small view-link",
    class: {
      'active-view-font': _vm.activeView === 'Users'
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Users")) + " (" + _vm._s(_vm.allUsersCount) + ") ")])]), _vm.user.access.accessLevel === 'company_admin' ? _c('div', {
    class: {
      'active-view': _vm.activeView === 'Settings'
    },
    attrs: {
      "id": "settingsView"
    },
    on: {
      "click": function ($event) {
        return _vm.changeFromUsersView('Settings');
      }
    }
  }, [_c('p', {
    staticClass: "content-small view-link",
    class: {
      'active-view-font': _vm.activeView === 'Settings'
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Settings")) + " ")])]) : _vm._e(), _c('div', {
    class: {
      'active-view': _vm.activeView === 'UserGroups'
    },
    attrs: {
      "id": "userGroupsView"
    },
    on: {
      "click": function ($event) {
        return _vm.changeFromUsersView('UserGroups');
      }
    }
  }, [_c('p', {
    staticClass: "content-small view-link",
    class: {
      'active-view-font': _vm.activeView === 'UserGroups'
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("UserGroups")) + " ")])])]), _vm.activeView === 'Users' ? _c('HeaderToolbar', {
    attrs: {
      "module": 'admin'
    }
  }, [_c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important"
    },
    attrs: {
      "name": "add-user-button"
    },
    on: {
      "click": _vm.changeAddUserPanelStatus
    }
  }, [_c('img', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    },
    attrs: {
      "src": require("../../assets/images/plusSign.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddUser")) + " ")])]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("../../assets/images/•.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticStyle: {
      "margin-left": "0px"
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "background": "white"
    },
    attrs: {
      "autocomplete": "off",
      "outlined": "",
      "hide-details": "",
      "id": 'search-bar',
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('Search') + '...'
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('div', {
    staticClass: "toggle-wrapper",
    on: {
      "click": _vm.toggleActiveUsers
    }
  }, [_c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": _vm.onlyActiveUsers ? require('../../assets/images/toggle-active.svg') : require('../../assets/images/toggle-inactive.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("OnlyActiveUsers")) + " (" + _vm._s(((_vm$headerData = _vm.headerData) === null || _vm$headerData === void 0 ? void 0 : _vm$headerData.activeUsersCount) || 0) + ") ")])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }