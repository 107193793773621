var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-groups-content"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("UserGroups")))]), _c('CompanyGroups'), _vm.addGroupOpen && Object.keys(_vm.groups).length - 1 ? _c('div', {
    staticStyle: {
      "width": "100%",
      "margin-right": "20px",
      "margin-top": "12px"
    }
  }, [_vm.addGroupOpen ? _c('v-text-field', {
    ref: "groupAdd",
    staticStyle: {
      "width": "100%",
      "max-width": "616px"
    },
    attrs: {
      "hide-details": "",
      "dense": "",
      "outlined": ""
    },
    on: {
      "focusout": _vm.addNewGroup,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return $event.target.blur();
      }
    },
    model: {
      value: _vm.addGroupName,
      callback: function ($$v) {
        _vm.addGroupName = $$v;
      },
      expression: "addGroupName"
    }
  }) : _vm._e()], 1) : _vm._e(), Object.keys(_vm.groups).length > 0 && _vm.user.isAdmin ? _c('div', {
    staticClass: "button-container"
  }, [_c('div', {
    staticClass: "link-small",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.addGroupButton
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t("AddAnother")) + " ")])]) : _vm._e(), Object.keys(_vm.groups).length < 1 && _vm.user.isAdmin ? _c('div', {
    staticClass: "button-container",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('div', {
    staticClass: "link-small",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.addGroupButton
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t("AddFirst")) + " ")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }