<template>
  <div v-if="data" name="wrapper" class="table-wrapper" :key="updateKey">
    <TableHeader :columns="data.columns" @reload="(column) => $emit('reload', column)" />
    <template v-if="data.rows.length > 0">
      <TableRow @click="(rowData) => $emit('click', rowData)" v-for="row in data.rows" :columns="data.columns" @reload="$emit('reload')" :row-data="row" :key="row._id" />
    </template>
    <div v-else style="height: 64px; padding: 12px 16px; line-height: 40px; background-color: white; border-radius: 0 0 8px 8px;" class="content-normal">{{ $t("NoResultsFound") }}</div>
  </div>
</template>

<script>
import TableHeader from "@/components/TableHeader";
import TableRow from "@/components/TableRow";

export default {
  name: "Table",
  components: {TableRow, TableHeader},
  props: ["data", "updateKey"]
}
</script>

<style scoped>
.table-wrapper {
  border-radius: 0 0 8px 8px;

}
</style>