<template>
  <div id="admin-content" class="main-wrapper">
    <Table @click="rowClicked" :data="tableData" :update-key="updatedUser" @reload="getUsersTableContent" />
    <UserSidepanel
        v-if="userPanelOpen"
        @overlayClicked="closeUserPanel"
        @editUser="editUser"
        :key="userUpdated"
        :userId="clickedUser._id"
    />
    <AddUserSidepanel
        :edit="true"
        :editable-user="userToEdit"
        @closed="closeEditUserPanel"
        v-if="editUserPanelOpen"
        :feedback-only="showFeedbackField"
        :achievements-only="showAchievementsField"
        :close-overlay-click="false"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Table from "@/components/Table.vue"
import UserSidepanel from "@/components/Admin/UserSidepanel";
import AddUserSidepanel from "@/components/Admin/AddUserSidepanel";
import axios from "axios";

export default {
  name: "UsersContent",
  components: {
    UserSidepanel,
    AddUserSidepanel,
    Table
  },
  props: ["search", "updated", "activeUsersOnly"],
  data() {
    return {
      tableData: null,
      loaded: false,
      clickedUser: null,
      userPanelOpen: false,
      editUserPanelOpen: false,
      showFeedbackField: false,
      showAchievementsField: false,
      userToEdit: null,
      listings: null,
      tableCloser: 0,
      sortUpdater: 0,
      updatedUser: 0,
      userUpdated: 0,
      originalArr: null,
      filteredUsers: [],
      searchDebounce: null,
    };
  },
  async created() {
    await this.setupUsers();
    await this.getUsersTableContent();
  },
  mounted() {
  },
  watch: {
    async activeUsersOnly() {
      await this.getUsersTableContent();
    },
    usersUpdaterKey() {
      this.updatedUser++;
    },
    updated: async function () {
      await this.setupUsers();
      this.updatedUser++;
    },
    search: async function() {
      this.debounceSearch();
    },
  },
  computed: {
    ...mapGetters(["user", "users", "usersUpdaterKey", "groups"]),
    colleagues() {
      if (this.user.access.company.isAdmin) {
        return this.users;
      } else {
        return this.users.filter(user =>
            Object.keys(user.access.groups)
                .some((groupId) => this.user.access.groups[groupId]?.isAdmin)
        );
      }
    }
  },
  methods: {
    async getUsersTableContent(column = null) {
      if (!column && this.tableData) {
        if (this.tableData) {
          for (const column of this.tableData.columns) {
            if (column.sort) {
              await this.getUsersTableContent(column);
              return; // Exit the loop and function once the content is fetched
            }
          }
        }
      }
      const response = await axios.post('/api/admin/users/table', {filters: {search: this.search, activeOnly: this.activeUsersOnly}, sort: column});
      this.tableData = response.data;
      this.updatedUser++;
      // Emit data to header
      let int = 0;
      for (const row of this.tableData.rows) {
        if (row.userStatus === 'Active') {
          int ++;
        }
      }
      this.$emit('headerData', {activeUsersCount: int})
    },
    editUser(user, feedbackField, achievementsField) {
      this.userToEdit = user;
      this.showFeedbackField = feedbackField;
      this.showAchievementsField = achievementsField;
      this.editUserPanelOpen = true;
    },
    async closeEditUserPanel(updating) {
      this.showAchievementsField = false;
      this.showFeedbackField = false;
      this.userToEdit = null;
      this.editUserPanelOpen = false;
      if (updating) {
        await this.setupUsers();
        this.userUpdated++;
        this.sortUpdater++;
      }
      await this.getUsersTableContent()
    },
    rowClicked(rowData) {
      if (this.user.access.accessLevel === "company_admin" || this.user.access.accessLevel === "group_admin") {
        this.clickedUser = this.users.find((user) => user._id === rowData._id)
        this.userPanelOpen = true;
      }
    },
    closeUserPanel() {
      this.clickedUser = null;
      this.userPanelOpen = false;
    },
    async setupUsers() {
      await this.getUsers();
    },
    ...mapActions(["getUsers", "getGroups"]),
    debounceSearch() {
      // Clear the existing timeout
      if (this.searchDebounce) {
        clearTimeout(this.searchDebounce);
      }
      // Set a new timeout
      this.searchDebounce = setTimeout(async () => {
        await this.getUsersTableContent(); // Default call if no sorted column
      }, 500); // 500 milliseconds delay
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  background-color: #f4f5f7;
  flex-wrap: nowrap;
  flex-direction: column;
}

.col {
  padding: 0;
}

.column-width {
  min-width: 208px;
  max-width: 208px;
}

.customer-row {
  margin-bottom: 8px;
  background-color: transparent;
}

.object-row {
  background: #ffff;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  margin-top: 16px;
}

.search-button {
  width: 260px;
}

.columns-wrapper {
  gap: 24px;
  margin-top: 28px;
  display: none;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  margin-top: 5px;
}

.task-name {
  margin-left: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
}

.task-date {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
  margin-left: 8px;
}

.task-date-row {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #000000;
  margin-top: -22px;
  margin-left: 34px;
}

.relatedCustomer-name {
  font-family: Inter, "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-left: 8px;
  width: calc(80%);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.parentName {
  font-family: Inter, "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-left: 8px;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-desc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* black */
  color: #000000;
  margin-top: 7px;
}

.gray-text {
  color: #75787a;
  font-size: 14px !important;
}

.search-row {
  width: 20px;
  height: 20px;
}

.images {
  display: flex;
  flex-direction: row;
  //align-items: flex-start;
  padding: 4px;
  position: static;
  width: 24px;
  height: 24px;
  background: #f4f5f7;
  border-radius: 100px;
  cursor: pointer;
}

.no-active {
  max-height: 200px;
  overflow: scroll;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.first-component {
  background: #ffffff;
  height: 56px;
  width: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px 0 0 8px;
}

.tasks-row {
  margin-bottom: -8px;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: static;
  width: 16px;
  height: 16px;
  left: 0px;
  top: 0px;
  margin-top: 8px;
  margin-left: 8px;
  background: #f4f5f7;
  border-radius: 100%;
}

.checkbox-outside {
  background: #f4f5f7;
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.second-component {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  min-width: 440px;
  height: 56px;
  left: 57px;
  top: 0px;
  margin: 0px 1px;
}

.third-component {
  display: flex;
  align-items: center;
  padding: 12px 16px;

  height: 56px;
  width: 160px;
  /* white */

  background: #ffffff;

  /* Inside Auto Layout */

  margin: 0px 1px;
}

.fourth-component {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  min-width: 25%;
  height: 56px;
  min-width: 160px;
  background: #ffffff;
  margin: 0px 1px;
}

.fifth-component {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 56px;
  width: 160px;
  /* white */

  background: #ffffff;

  /* Inside Auto Layout */

  margin: 0px 1px;
}

.sixth-component {
  display: flex;
  align-items: flex-start;
  padding: 12px 16px;

  height: 56px;
  border-radius: 0 8px 8px 0;
  width: 160px;
  /* white */

  background: #ffffff;

  /* Inside Auto Layout */
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.filter-image {
  height: 13.728036880493164px;
  width: 12.532658576965332px;
  left: 1.733642578125px;
  top: 1.3330078125px;
  border-radius: 0px;
  margin-top: 1px;
}

.menu-item-person {
  cursor: pointer;
  background-color: white;
  margin-top: 0 !important;
  padding: 12px;

  &:hover {
    background-color: #e6e8ec;
  }

  p {
    font-size: 14px;
    margin-bottom: 0 !important;
  }
}

.white-background {
  background-color: #ffffff;
}

#listing-header,
#listing-content {
  transition: width, margin-left 300ms ease-in-out;
}

.slide-animation {
  margin-left: 256px !important;
}

.slide-animation-close {
  margin-left: 0 !important;
}

.ghost-card {
  background-color: #c1c1c1 !important;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
}

.scrollable {
  min-height: 48px;
}

.dot {
  font-weight: 900;
}
</style>
