var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "corporate-image-section"
  }, [_vm.edit ? _c('div', [_c('div', {
    staticClass: "upload-image-wrapper"
  }, [_c('button', {
    staticClass: "add-files-button",
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.startUploadImage();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/image.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-small",
    staticStyle: {
      "font-weight": "400",
      "min-width": "64px !important",
      "justify-content": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("UploadImage")) + " ")])]), _c('input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "id": "corp-image-uploader"
    },
    on: {
      "change": function (event) {
        return _vm.uploadImage(event);
      }
    }
  }), _vm.image ? _c('v-row', {
    staticClass: "file-row"
  }, [_c('span', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('a', {
    staticClass: "file-name",
    attrs: {
      "href": _vm.image.value,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.image.name) + " ")]), _c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": require("../../assets/images/action-delete.svg"),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.deleteImage();
      }
    }
  })])]) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.edit,
      expression: "edit"
    }],
    staticClass: "bottom-con"
  }, [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": _vm.cancelPress
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "transparent-button",
    staticStyle: {
      "background": "black !important",
      "color": "white"
    },
    on: {
      "click": _vm.savePress
    }
  }, [_c('img', {
    staticClass: "img16",
    attrs: {
      "src": require("../../assets/images/checkmarkCircled.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")])])])]) : _c('div', [_c('div', {
    staticClass: "corporate-image-wrapper"
  }, [_vm.image ? _c('img', {
    key: _vm.image.value,
    attrs: {
      "src": _vm.image.value,
      "alt": ""
    }
  }) : _c('div', {
    staticClass: "corporate-image-placeholder"
  }), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("Image's") + " " + _vm.$t("place") + ": " + _vm.$t("3rd")) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }