var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-property"
    }
  }, [_c('div', {
    staticClass: "modal-wrapper",
    attrs: {
      "id": "panel-wrapper-property"
    }
  }, [_c('div', {
    staticClass: "content-overlay",
    staticStyle: {
      "height": "100%",
      "display": "flex",
      "flex-direction": "column"
    },
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header",
    staticStyle: {
      "z-index": "126"
    }
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px"
    }
  }, [_c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ChangeContactInformation")) + " ")])])], 1), _c('div', {
    staticStyle: {
      "padding": "24px 32px",
      "height": "100%",
      "overflow-y": "scroll"
    }
  }, [_c('v-row', {
    staticStyle: {
      "padding-bottom": "12px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Company")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('CompanyName')
    },
    model: {
      value: _vm.form.companyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "companyName", $$v);
      },
      expression: "form.companyName"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('FranchiseName')
    },
    model: {
      value: _vm.form.franchiseName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "franchiseName", $$v);
      },
      expression: "form.franchiseName"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('RegCode')
    },
    model: {
      value: _vm.form.regCode,
      callback: function ($$v) {
        _vm.$set(_vm.form, "regCode", $$v);
      },
      expression: "form.regCode"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('VATNumber')
    },
    model: {
      value: _vm.form.vatNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "vatNumber", $$v);
      },
      expression: "form.vatNumber"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Address")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "24px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Street')
    },
    model: {
      value: _vm.form.street,
      callback: function ($$v) {
        _vm.$set(_vm.form, "street", $$v);
      },
      expression: "form.street"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('ApartmentNumber')
    },
    model: {
      value: _vm.form.apartmentNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "apartmentNumber", $$v);
      },
      expression: "form.apartmentNumber"
    }
  }), _c('v-row', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "gap": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('City')
    },
    model: {
      value: _vm.form.city,
      callback: function ($$v) {
        _vm.$set(_vm.form, "city", $$v);
      },
      expression: "form.city"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('ZipCode')
    },
    model: {
      value: _vm.form.zipCode,
      callback: function ($$v) {
        _vm.$set(_vm.form, "zipCode", $$v);
      },
      expression: "form.zipCode"
    }
  })], 1), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Country')
    },
    model: {
      value: _vm.form.country,
      callback: function ($$v) {
        _vm.$set(_vm.form, "country", $$v);
      },
      expression: "form.country"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Contact")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "24px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('WebsiteUrl')
    },
    model: {
      value: _vm.form.website,
      callback: function ($$v) {
        _vm.$set(_vm.form, "website", $$v);
      },
      expression: "form.website"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Email')
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('PhoneNumber')
    },
    model: {
      value: _vm.form.phoneNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phoneNumber", $$v);
      },
      expression: "form.phoneNumber"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("BankAccount")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "24px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": 'property-owner-business-field',
      "placeholder": _vm.$t('BankName')
    },
    model: {
      value: _vm.form.bankName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bankName", $$v);
      },
      expression: "form.bankName"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": 'property-owner-business-field',
      "placeholder": _vm.$t('BICSWIFT')
    },
    model: {
      value: _vm.form.swift,
      callback: function ($$v) {
        _vm.$set(_vm.form, "swift", $$v);
      },
      expression: "form.swift"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": 'property-owner-business-field',
      "placeholder": _vm.$t('BankAccountNumber')
    },
    model: {
      value: _vm.form.accountNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accountNumber", $$v);
      },
      expression: "form.accountNumber"
    }
  })], 1)], 1), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "buttons-row",
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "save-button",
    on: {
      "click": function ($event) {
        return _vm.updateContactInformation();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Save")) + " ")])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }